export const CONTATO = {
    CATEGORIA: {
        TELEFONE_RESIDENCIAL: 1,
        TELEFONE_CELULAR: 2,
        TELEFONE_COMERCIAL: 3,
        EMAIL: 4
    }
}

export const TIPO_USUARIO = {
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO'
}

export const TRIAGEM = {
    ETAPA_1: 1,
    ETAPA_2: 2,
    ETAPA_3: 3,
    ETAPA_4: 4,
    ETAPA_5: 5,
    ETAPA_6: 6,
    ETAPA_7: 7,
    ETAPA_8: 8,
    ETAPA_9: 9,
    ETAPA_10: 10
}

export const ID_TABS = {
    INICIO: 0,
    SOBRE_TRIAGEM_AUDITIVA: 1,
    INICIAR_TRIAGEM_AUDITIVA: 2
}

// 24 pro lado esquerdo + 24 pro lado direito;
export const NUMERO_ETAPAS_TESTE = 48;

export const PERMISSOES = {
    ALOFONO: 'ALOFONO_ALOFONO',
    VISUALIZACAO: 'ALOFONO_VISUALIZACAO',
}