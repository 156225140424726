import React from 'react';
import {
    SttGrid
} from '@stt-componentes/core';

const SttGridContainerAlofono = (props) => {
    return (
        <SttGrid
            container
            direction="row"
            justifyContent="center"
            style={{ maxWidth: '100%' }}
            {...props}
        />
    );
}
export default SttGridContainerAlofono;