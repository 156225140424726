import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Interno from './interno/index';
import Erro from './erro';

const Home = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Interno />} />
                <Route path="/erro" element={<Erro />} />
                <Route path="/externo-alofono" element={<Erro />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Home;
