import React, { useState, useEffect, useRef } from 'react'
import {
    SttGrid
} from '@stt-componentes/core';
import { ThemeProvider } from "styled-components";
import theme from '../../../theme';
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Icon from '@material-ui/core/Icon';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import LogoAlofonoSemTexto from '../../../img/logoAlofonoSemTexto.svg';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    slider: {
        color: '#66C3BE'
    },
    paper: {
        backgroundColor: '#1671B9',
        width: theme.spacing(42),
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1)
    },
    imageIcon: {
        height: '35px',
        width: '35px',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        display: 'flex',
        padding: theme.spacing(1)
    },
    iconRoot: {
        textAlign: 'center'
    },
    iconePlayPause: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        color: ({ usuarioJaOuviuAudio }) => usuarioJaOuviuAudio ? '#828282' : '#FFFFFF',
        '&:hover': {
            cursor: 'pointer',
        }
    }
}));

const SttAudioPlayerAlofono = (props) => {
    const { audios, audioTeste, passo } = props;
    const ruido = useRef(null);

    const [tocarAudios, setTocarAudios] = useState(false);
    const [duracaoAudioTeste, setDuracaoAudioTeste] = useState(null);
    const [porcentagem, setPorcentagem] = useState(0);
    const [usuarioJaOuviuAudio, setUsuarioJaOuviuAudio] = useState(false);
    const classes = useStyles({ usuarioJaOuviuAudio });

    useEffect(() => {
        if (!audioTeste) {
            document.getElementById('audioDigito').src = audios[0];
            if (passo > 0) {
                tocarSons();
            }
        }

        return () => {
            setPorcentagem(0);
            setUsuarioJaOuviuAudio(false);
        }
    }, []);

    const tocarSons = () => {
        if (audioTeste) {
            if (!tocarAudios) {
                let listenerTeste = function () {
                    ruido.current.removeEventListener('ended', listenerTeste, false);
                    setPorcentagem(0);
                    setTocarAudios(false);
                }
                ruido.current.addEventListener("ended", listenerTeste);

                ruido.current.play();
                setTocarAudios(true);
            } else {
                ruido.current.pause();
                setTocarAudios(false);
            }
            return;
        }

        let audioTag = document.getElementById('audioDigito');

        let listener1 = function () {
            setPorcentagem(33);
            audioTag.removeEventListener('ended', listener1, false);

            audioTag.addEventListener("ended", listener2);
            audioTag.src = audios[1];
            audioTag.play();
        }

        let listener2 = function () {
            setPorcentagem(67);
            audioTag.removeEventListener('ended', listener2, false);

            audioTag.addEventListener("ended", listener3);
            audioTag.src = audios[2];
            audioTag.play();
        }

        let listener3 = function () {
            setPorcentagem(100);
            audioTag.removeEventListener('ended', listener3, false);

            setUsuarioJaOuviuAudio(true);
            setTocarAudios(false);
        }

        audioTag.addEventListener("ended", listener1);
        setTocarAudios(true);
        audioTag.play();
    }

    const calcularPorcentual = (e) => {
        let percent = 0;
        if (duracaoAudioTeste) {
            percent = ((e.currentTarget.currentTime / duracaoAudioTeste) * 100).toFixed(2);
        }

        if (percent && percent > 0) {
            setPorcentagem(+percent);
        }
    }

    return (
        <>
            {
                audioTeste &&
                <audio
                    ref={ruido}
                    onTimeUpdate={calcularPorcentual}
                    onLoadedData={(e) => {
                        setDuracaoAudioTeste(e.currentTarget.duration)
                    }}
                    src={global.audioConfig.audioTeste.audioTeste}
                ></audio>
            }
            <Paper className={classes.paper}>
                {
                    <ThemeProvider theme={theme}>
                        <SttGrid container spacing={2} justifyContent="center" alignItems="center">
                            <SttGrid item xs={3}>
                                <Icon classes={{ root: classes.iconRoot }}>
                                    <img className={classes.imageIcon} src={LogoAlofonoSemTexto} />
                                </Icon>
                            </SttGrid>
                            <SttGrid item xs={2}>
                                {
                                    tocarAudios ?
                                        <PauseIcon
                                            disabled={audios}
                                            className={classes.iconePlayPause}
                                            onClick={() => {
                                                if (!audios) {
                                                    tocarSons();
                                                }
                                            }}
                                        /> :
                                        <PlayArrowIcon
                                            className={classes.iconePlayPause}
                                            onClick={() => {
                                                if (!usuarioJaOuviuAudio) {
                                                    tocarSons();
                                                }
                                            }}
                                        />
                                }
                            </SttGrid>
                            <SttGrid item xs={7}>
                                <Slider value={porcentagem} className={classes.slider} />
                            </SttGrid>
                        </SttGrid>
                    </ThemeProvider>
                }
            </Paper>
        </>
    );
}

export default SttAudioPlayerAlofono;