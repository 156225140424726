'use strict';

const Util = require('../util/util');
const { ValidationError } = require('../erros');

const Paciente = {

    pesquisar: async (dbClient, idPaciente, idEnderecoPaciente) => {
        const consulta = dbClient('public.paciente AS p');

        consulta.where('p.id', '=', idPaciente);

        consulta.select(
            'p.*'
        );

        return consulta.first();
    },

    salvar: async (dbClient, transactionHandler, paciente) => {
        let id = paciente.id;
        let cpf = null;
        let pacienteCpf = null;
        let pacienteCns = null;

        if (paciente.cpf) {
            cpf = await Util.removerMascaraNumero(paciente.cpf);
        }
        if (!id) {
            // Verifica se existe paciente cadastrado com o CPF e/ou CNS.
            // Se existir gera erro, pois nesse caso o paciente deve ser 
            // selecionado através da busca e não ter seus dados preenchidos
            // como se fosse um paciente novo.
            if (cpf) {
                pacienteCpf = await Paciente.buscarPorCpf(dbClient, cpf);
            }
            if (paciente.cns) {
                pacienteCns = await Paciente.buscarPorCns(dbClient, paciente.cns);
            }
            if ((pacienteCpf && pacienteCpf.length > 0) &&
                (pacienteCns && pacienteCns.length > 0)) {
                throw new ValidationError('Já existe um paciente com o CPF e o CNS informados');
            } else if (pacienteCpf && pacienteCpf.length > 0) {
                throw new ValidationError('Já existe um paciente com o CPF informado');
            } else if (pacienteCns && pacienteCns.length) {
                throw new ValidationError('Já existe um paciente com o CNS informado');
            }
        }

        const dados = {
            nome: paciente.nome,
            cpf: cpf,
            cartao_sus: paciente.cns,
            id_sexo: paciente.genero.id,
            data_nascimento: paciente.dataNascimento
        }

        if (paciente.nomeMae) {
            dados.nome_mae = paciente.nomeMae;
        }

        if (paciente.grupoSanguineo) {
            dados.grupo_sanguineo = paciente.grupoSanguineo;
        }

        if (paciente.fatorRh) {
            dados.fator_rh = paciente.fatorRh;
        }

        let idEndereco;
        if (id) {
            await dbClient('public.paciente')
                .where('id', '=', id)
                .update(dados)
                .transacting(transactionHandler);
        } else {
            const result = await dbClient
                .insert(dados)
                .into('public.paciente')
                .returning('id')
                .transacting(transactionHandler);

            id = result[0];
        }

        if(paciente['id_endereco_paciente']) {
            let enderecoPaciente = {
                logradouro: paciente.logradouro,
                bairro: paciente.bairro,
                cep: paciente.cep,
                municipio: paciente.municipio,
                uf: paciente.uf,
                pais: paciente.pais,
                numero: paciente.numero,
                id_paciente: id
            };
            //Altera os dados do endereço do paciente
            idEndereco = await Paciente.atualizarEndereco(dbClient, transactionHandler, paciente.id_endereco_paciente, enderecoPaciente);
        } else {
            // Salva dados de endereço
            idEndereco = await Paciente.salvarEndereco(dbClient, transactionHandler, id, paciente);
        }

        // Relaciona o endereço ao paciente
        await dbClient('public.paciente')
            .where('id', '=', id)
            .update({ id_endereco_paciente: idEndereco })
            .transacting(transactionHandler);

        // Salva os contatos
        await Paciente.salvarContatos(dbClient, transactionHandler, id, paciente);
        paciente.id = id;
        paciente.id_endereco_paciente = idEndereco;
        return paciente;
    },

    /**
     * Cria um registro de paciente com os dados mínimos necessários
     * @param {*} dbClient 
     * @param {*} transactionHandler 
     * @param {*} paciente 
     */
    salvarMinimo: async (dbClient, transactionHandler, paciente) => {
        let cpf = null;
        let pacienteCpf = null;

        if (paciente.cpf) {
            cpf = await Util.removerMascaraNumero(paciente.cpf);
        }

        // Verifica se existe paciente cadastrado com o CPF e/ou CNS.
        // Se existir gera erro, pois nesse caso o paciente deve ser 
        // selecionado através da busca e não ter seus dados preenchidos
        // como se fosse um paciente novo.
        if (cpf) {
            pacienteCpf = await Paciente.buscarPorCpf(dbClient, cpf);
        }
        if (pacienteCpf && pacienteCpf.length > 0) {
            throw new ValidationError('Já existe um paciente com o CPF informado');
        }

        const dados = {
            nome: paciente.nome,
            cpf: cpf,
            id_sexo: paciente.genero.id,
            data_nascimento: paciente.dataNascimento,
            nome_mae: paciente.nomeMae
        }

        const result = await dbClient
            .insert(dados)
            .into('public.paciente')
            .returning('id')
            .transacting(transactionHandler);

        const idPaciente = result[0];
        return { id: idPaciente };
    },

    atualizar: async (dbClient, transactionHandler, idPaciente, dados) => {
        await dbClient('public.paciente')
            .where('id', '=', idPaciente)
            .update(dados)
            .transacting(transactionHandler);
    },

    atualizarProvisorio: async (dbClient, transactionHandler, idPaciente, dados) => {
        await dbClient('public.paciente_provisorio')
            .where('id', '=', idPaciente)
            .update(dados)
            .transacting(transactionHandler);
    },

    salvarEndereco: async (dbClient, transactionHandler, idPaciente, endereco) => {
        const dados = {
            id_paciente: idPaciente,
            logradouro: endereco.logradouro,
            bairro: endereco.bairro
        };
        if (endereco.cep) {
            dados.cep = endereco.cep;
        }
        if (endereco.municipio) {
            dados.id_cidade = endereco.municipio.id;
        }
        if (endereco.uf) {
            dados.id_estado = endereco.uf.id;
        }
        if (endereco.pais) {
            dados.id_pais = endereco.pais.id;
        }
        if (endereco.numero) {
            dados.numero = endereco.numero;
        }
        const result = await dbClient
            .insert(dados)
            .into('public.endereco_paciente')
            .returning('id')
            .transacting(transactionHandler);

        return result[0];
    },

    atualizarEndereco: async (dbClient, transactionHandler, idEndereco, endereco) => {
        const dados = {
            id_paciente: endereco.idPaciente,
            logradouro: endereco.logradouro,
            bairro: endereco.bairro
        };
        if (endereco.cep) {
            dados.cep = endereco.cep;
        }
        if (endereco.municipio) {
            dados.id_cidade = endereco.municipio.id;
        }
        if (endereco.uf) {
            dados.id_estado = endereco.uf.id;
        }
        if (endereco.pais) {
            dados.id_pais = endereco.pais.id;
        }
        if (endereco.numero) {
            dados.numero = endereco.numero;
        }
        const result = await dbClient('public.endereco_paciente')
            .where('id', '=', idEndereco)
            .update(dados)
            .returning('id')
            .transacting(transactionHandler);

        return result[0];
    },

    salvarContatos: async (dbClient, transactionHandler, idPaciente, dados) => {
        // Remove todos os contatos do paciente
        await Paciente.removerContatos(dbClient, transactionHandler, idPaciente);

        // Insere o primeiro contato, caso tenha sido informado
        if (dados.tipoContato1 && dados.contato1) {
            let contato1 = await Util.removerMascaraNumero(dados.contato1);
            await dbClient
                .insert({
                    id_paciente: idPaciente,
                    id_tipo_contato: dados.tipoContato1,
                    contato: contato1,
                    contato_whatsapp: dados.contato1Whatsapp ? true : false
                })
                .into('public.contato_paciente')
                .transacting(transactionHandler);
        }

        // Insere o segundo contato, caso tenha sido informado
        if (dados.tipoContato2 && dados.contato2) {
            let contato2 = await Util.removerMascaraNumero(dados.contato2);
            await dbClient
                .insert({
                    id_paciente: idPaciente,
                    id_tipo_contato: dados.tipoContato2,
                    contato: contato2,
                    contato_whatsapp: dados.contato2Whatsapp ? true : false
                })
                .into('public.contato_paciente')
                .transacting(transactionHandler);
        }
    },

    removerContatos: async (dbClient, transactionHandler, idPaciente) => {
        await dbClient('public.contato_paciente')
            .where('id_paciente', '=', idPaciente)
            .del()
            .transacting(transactionHandler);
    },

    buscarPorCns: async (dbClient, cns) => {
        return await dbClient('public.paciente AS p')
            .where('p.cartao_sus', '=', cns);
    },

    buscarPorCpf: async (dbClient, cpf) => {
        return await dbClient('public.paciente AS p')
            .where('p.cpf', '=', cpf);
    }
};

module.exports = Paciente;