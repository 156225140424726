const PAGINA_INICIAL = 4;
const ULTIMA_PAGINA = -18;
const ARQUIVO_INICIAL = 0;
const ARQUIVO_FINAL = 9;
const LADO_DIREITO = 'D';
const LADO_ESQUERDO = 'E';

global.audioConfig = {
    carregamentoFinalizado: false,
    failList: []
};

const nextNumber = (pasta, arquivo) => {
    if (pasta === ULTIMA_PAGINA && arquivo === `${ARQUIVO_FINAL}${LADO_ESQUERDO}`) {
        // Baixou último arquivo da última pasta
        fetchFailedList();
        return;
    }

    if (arquivo === `${ARQUIVO_FINAL}${LADO_ESQUERDO}`) {
        // Baixou último arquivo da pasta, vai para a próxima pasta
        pasta = pasta - 2;
        fetchAudio(pasta, `${ARQUIVO_INICIAL}${LADO_DIREITO}`, nextNumber);
        return;
    }

    let numeroArquivo = arquivo.substring(0, 1);
    if (arquivo.includes(LADO_DIREITO)) {
        // Baixou lado direito, vai para o esquerdo
        fetchAudio(pasta, `${numeroArquivo}${LADO_ESQUERDO}`, nextNumber);
        return;
    }

    // Baixo o lado esquerdo, vai para o próximo número do lado direito
    numeroArquivo++;
    fetchAudio(pasta, `${numeroArquivo}${LADO_DIREITO}`, nextNumber);
}

const fetchFailedList = () => {
    if (global.audioConfig.failList.length === 0) {
        global.audioConfig.carregamentoFinalizado = true;
        return;
    }

    let removed = global.audioConfig.failList.splice(0, 1);
    removed = removed[0];
    const { pasta, arquivo } = removed;
    fetchAudio(pasta, arquivo, fetchFailedList);
}

const fetchAudio = (pasta, arquivo, nextAudio) => {
    const myRequest = new Request(`${process.env.PUBLIC_URL}/audios/${pasta}/${arquivo}.mp3`);

    fetch(myRequest)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Erro no download do áudio');
            }
            return response.blob();
        })
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
            if (!global.audioConfig[pasta]) {
                global.audioConfig[pasta] = {};
            }
            global.audioConfig[pasta][arquivo] = url;
        })
        .catch((err) => {
            console.error(err)
            global.audioConfig.failList.push({ pasta, arquivo });
        })
        .finally(() => {
            nextAudio && nextAudio(pasta, arquivo);
        });
}

const initFetch = async () => {
    fetchAudio(PAGINA_INICIAL, `${ARQUIVO_INICIAL}${LADO_DIREITO}`, nextNumber);
    fetchAudio('audioTeste', 'audioTeste');
    fetchAudio('ruido', 'ruido');
}

export default initFetch;