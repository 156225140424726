import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttGrid,
    SttHeading,
    SttTranslate
} from '@stt-componentes/core';
import BackspaceLogo from '../../../../../img/backspace.svg';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    circulo: {
        borderRadius: '50%',
        backgroundColor: '#FFFFFF',
        height: theme.spacing(7),
        width: theme.spacing(7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    circuloTransparente: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'default'
        },
    },
    logo: {
        transform: 'scale(0.5)',
        height: '100%',
        width: '100%'
    },
    itemGrid: {
        marginRight: theme.spacing(6),
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    }
}));

const TecladoNumerico = ({ onClickDigito, onClickApagarDigito }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <>
            <SttGrid container spacing={isXs ? 1 : 3}>
                <SttGrid item container justifyContent="center" alignItems="center">
                    <SttGrid item className={classes.itemGrid}>
                        <span className={classes.circulo} onClick={() => onClickDigito(1)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>1</SttHeading>
                        </span>
                    </SttGrid>
                    <SttGrid item className={classes.itemGrid}>
                        <span className={classes.circulo} onClick={() => onClickDigito(2)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>2</SttHeading>
                        </span>
                    </SttGrid>
                    <SttGrid item>
                        <span className={classes.circulo} onClick={() => onClickDigito(3)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>3</SttHeading>
                        </span>
                    </SttGrid>
                </SttGrid>

                <SttGrid item container justifyContent="center" alignItems="center">
                    <SttGrid item className={classes.itemGrid}>
                        <span className={classes.circulo} onClick={() => onClickDigito(4)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>4</SttHeading>
                        </span>
                    </SttGrid>
                    <SttGrid item className={classes.itemGrid}>
                        <span className={classes.circulo} onClick={() => onClickDigito(5)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>5</SttHeading>
                        </span>
                    </SttGrid>
                    <SttGrid item>
                        <span className={classes.circulo} onClick={() => onClickDigito(6)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>6</SttHeading>
                        </span>
                    </SttGrid>
                </SttGrid>

                <SttGrid item container justifyContent="center" alignItems="center">
                    <SttGrid item className={classes.itemGrid}>
                        <span className={classes.circulo} onClick={() => onClickDigito(7)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>7</SttHeading>
                        </span>
                    </SttGrid>
                    <SttGrid item className={classes.itemGrid}>
                        <span className={classes.circulo} onClick={() => onClickDigito(8)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>8</SttHeading>
                        </span>
                    </SttGrid>
                    <SttGrid item>
                        <span className={classes.circulo} onClick={() => onClickDigito(9)}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>9</SttHeading>
                        </span>
                    </SttGrid>
                </SttGrid>

                <SttGrid item container justifyContent="center" alignItems="center">
                    <SttGrid item className={classes.itemGrid}>
                        <span className={[classes.circuloTransparente, classes.circulo].join(' ')}></span>
                    </SttGrid>
                    <SttGrid item className={classes.itemGrid}>
                        <span className={classes.circulo} onClick={() => onClickDigito('0')}>
                            <SttHeading variant={isXs ? 'h2' : 'h3'}>0</SttHeading>
                        </span>
                    </SttGrid>
                    <SttGrid item>
                        <span className={classes.circulo} onClick={() => onClickApagarDigito()}>
                            <img src={BackspaceLogo} className={classes.logo} />
                        </span>
                    </SttGrid>
                </SttGrid>
            </SttGrid>
        </>
    );
};

export default SttTranslate('Teste')(TecladoNumerico);