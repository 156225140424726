import React from 'react';
import { connect } from 'react-redux';
import { TRIAGEM } from '../../../common/Constants';
import Questionario from './questionario/index';
import VamosIniciar from './configuracao/vamosIniciar';
import VerificacaoItens from './configuracao/verificacaoItens';
import TesteAudio from './configuracao/testeAudio';
import IniciarTesteRuido from './configuracao/iniciarTesteRuido';
import ResultadoTeste from '../../../componentes/interno/triagemAuditiva/teste/resultado';
import ResultadoFinal from '../../../componentes/interno/triagemAuditiva/resultado';
import ResultadoQuestionario from '../../../componentes/interno/triagemAuditiva/questionario/resultado';
import Teste from './teste';
import Agradecimento from '../../../componentes/interno/triagemAuditiva/agradecimento';
import {
    SttTranslate
} from '@stt-componentes/core';

const getEtapa = (etapa) => {
    switch (etapa) {
        case TRIAGEM.ETAPA_1:
            return <VamosIniciar />;
        case TRIAGEM.ETAPA_2:
            return <VerificacaoItens />;
        case TRIAGEM.ETAPA_3:
            return <TesteAudio />;
        case TRIAGEM.ETAPA_4:
            return <IniciarTesteRuido />;
        case TRIAGEM.ETAPA_5:
            return <Teste />
        case TRIAGEM.ETAPA_6:
            return <ResultadoTeste />
        case TRIAGEM.ETAPA_7:
            return <Questionario />
        case TRIAGEM.ETAPA_8:
            return <ResultadoQuestionario />
        case TRIAGEM.ETAPA_9:
            return <ResultadoFinal />
        case TRIAGEM.ETAPA_10:
            return <Agradecimento />
    }
}

const TriagemAuditiva = ({ passo }) => {

    return getEtapa(passo);
};

const mapStateToProps = (state) => {
    return {
        passo: state.index.triagemAuditiva.passo
    };
};

export default connect(mapStateToProps, null)(SttTranslate('TelaBoasVindas')(TriagemAuditiva));