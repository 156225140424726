import React from 'react';
import {
    SttButton
} from '@stt-componentes/core';
import styled from 'styled-components';
import { ThemeProvider } from "styled-components";
import theme from '../../theme';

const StyledButton = styled(SttButton)`
    font-weight: 700;
    line-height: 150%;
    min-height: 64px;
    font-size: ${props =>
        `${props.theme.spacing(2)}px`
    };
    borderRadius: ${props =>
        props.theme.spacing(1)
    };
    padding: ${props =>
        `0px ${props.theme.spacing(5)}px 0px`
    };
`;

const SttButtonExternoAlofono = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <StyledButton
                {...props}
            />
        </ThemeProvider>
    );
}
export default SttButtonExternoAlofono;