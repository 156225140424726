import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
    SttHeader,
    SttMainNavigationBar,
    SttAlerta,
    SttSplashScreen,
    SttTranslate
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '../../security/acl';
import { ID_TABS, PERMISSOES } from '../../common/Constants';
import { ThemeProvider } from '@material-ui/core/styles';
import themeSplashScreen from '../../themeSplashScreen.js';
import { resetarTeste } from '../../redux/actions'
import { setTabAtual } from '../../redux/actions/index';
import { TRIAGEM } from '../../common/Constants';

const Menu = ({ user, strings, resetarTeste, tabAtual, setTabAtual, passo }) => {
    const navigate = useNavigate();
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);

    //Alertas
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);

    const onClickMenu = (path) => {
        if (window.location.pathname === '/iniciarTriagemAuditiva' && TRIAGEM.ETAPA_10 !== passo) {
            //Verificar se o usuário quer mesmo sair do teste
            setOpcoesAlerta(
                [
                    {
                        title: strings.ok,
                        onClick: () => {
                            redirect(path, true);
                            setMostrarAlerta(false);
                        }
                    },
                    {
                        title: strings.cancelar,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]
            );
            setMostrarAlerta(true);
        } else if (window.location.pathname === '/iniciarTriagemAuditiva' && TRIAGEM.ETAPA_10 === passo) {
            redirect(path, true);
        } else {
            redirect(path, false);
        }
    }

    const redirect = (to, reset = false) => {
        if (reset) {
            resetarTeste();
        }
        navigate(to);
    }

    useEffect(() => {
        let menuOptions = submenus;
        if (user.credenciais) {
            //Tratamento de permissão do usuário
            if (!temPermissaoRBAC(user, PERMISSOES.ALOFONO)) {
                navigate(`/nao-autorizado`);
                return;
            }
            menuOptions = [...menuOptions,
            {
                id: '/inicio',
                text: strings.inicio,
                onClick: () => {
                    setTabAtual(ID_TABS.INICIO);
                }
            },
            {
                id: '/sobreTriagemAuditiva',
                text: strings.sobreTriagemAuditiva,
                onClick: () => {
                    setTabAtual(ID_TABS.SOBRE_TRIAGEM_AUDITIVA);
                }
            },
            {
                id: '/iniciarTriagemAuditiva',
                text: strings.iniciarTriagemAuditiva,
                onClick: () => {
                    setTabAtual(ID_TABS.INICIAR_TRIAGEM_AUDITIVA);
                }
            }
            ];
            navigate("/inicio");
            setSubmenus(menuOptions);
        }
    }, [user.credenciais]);

    useEffect(() => {
        switch (tabAtual) {
            case 0: //inicio
                onClickMenu('/inicio');
                break;

            case 1: //sobre a triagem auditiva
                onClickMenu('/sobreTriagemAuditiva');
                break;

            case 2: //iniciar triagem auditiva
                onClickMenu('/iniciarTriagemAuditiva');
                break;
        }
    }, [tabAtual]);

    return (
        <div>
            <SttHeader
                config={global.gConfig}
                titulo={global.gConfig.instancia_nome}
                onClickDuvidas={() => setOpenSS(!openSS)}
                redirectUrl={global.gConfig.url_redirect_cadastro_alofono}
            />
            <SttMainNavigationBar
                config={global.gConfig}
                titulo={global.gConfig.modulo_nome}
                submenus={submenus}
                selectedTab={tabAtual}
                callbackNavigationBar={(event, newTab) => {
                }}
            />
            <ThemeProvider theme={themeSplashScreen}>
                <SttAlerta
                    open={mostrarAlerta}
                    title={strings.atencao}
                    message={strings.duvidaSairTeste}
                    type='alert'
                    onClose={() => {
                        setMostrarAlerta(false);
                    }}
                    options={opcoesAlerta}
                />

                <SttSplashScreen
                    modulo={global.gConfig.modulo_nome}
                    versao={global.gConfig.modulo_versao}
                    novidades={global.gConfig.modulo_novidades}
                    textoAjuda={global.gConfig.texto_ajuda}
                    open={openSS}
                    setOpen={setOpenSS}
                />
            </ThemeProvider>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        tabAtual: state.index.tabAtual,
        passo: state.index.triagemAuditiva.passo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetarTeste: () => dispatch(resetarTeste()),
        setTabAtual: (idTabAtual) => dispatch(setTabAtual(idTabAtual))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Cabecalho')(Menu));