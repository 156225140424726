import { combineReducers } from 'redux';
import TYPES from './types';

const INITIAL_STATE = {
    isAuthenticated: false,
    tcleTime: null,
    message: '',
    tabAtual: 0,
    user: {
        nome: '',
        email: '',
        foto: '',
        respostaQuestionario: []
    },
    triagemAuditiva: {
        id: null,
        consideraProblemaAudicao: null,
        compreendeConversaAmbientesRuidosos: null,
        tipoFone: 'I',
        passo: 1, // passo da triagem. 1 = primeira etapa, 2 = segunda etapa, ..., 7 = questionário, etc.
        questionario: ['', '', '', '', ''],
        resultadoQuestionario: undefined
    },
    telas: [],
    teste: {
        passo: 0, // de 0 a 47, representa as 48 etapas do teste
        problemasTeste: false,
        respostas: [],
        resultado: undefined
    }
};

const index = (state, action) => {
    if (!state) {
        state = { ...INITIAL_STATE };
    }
    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }
        case TYPES.SET_TCLE_TIME:
            return {
                ...state,
                tcleTime: payload.tcleTime
            }
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        case TYPES.SET_RESPOSTA_QUESTIONARIO:
            return {
                ...state,
                user: {
                    ...state.user,
                    respostaQuestionario: payload.respostas
                }
            }
        case TYPES.LOGOUT:
            return INITIAL_STATE;

        case TYPES.SET_MESSAGE:
            return {
                ...state,
                message: payload.message
            }
        case TYPES.RESPONDER_PERGUNTA:
            const respostas = state.triagemAuditiva.questionario.map((item, index) => {
                if (index !== payload.pergunta) {
                    return item
                }

                return payload.resposta;
            });
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    questionario: [...respostas]
                }
            }
        case TYPES.SET_ID:
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    id: payload.id
                }
            }
        case TYPES.CONSIDERA_PROBLEMA_AUDICAO:
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    consideraProblemaAudicao: payload.resposta
                }
            }
        case TYPES.COMPREENDE_CONVERSA_AMBIENTES_RUIDOSOS:
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    compreendeConversaAmbientesRuidosos: payload.resposta
                }
            }
        case TYPES.TIPO_FONE:
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    tipoFone: payload.tipo
                }
            }
        case TYPES.INICIAR_CONTAGEM_TEMPO_TELA:
            return {
                ...state,
                telas: [
                    ...state.telas,
                    { numero: (payload.tela+1), dataHoraInicio: payload.timestamp }
                ]
            };
        case TYPES.FINALIZAR_CONTAGEM_TEMPO_TELA:
            if (!state.telas.length) {
                return state;
            }
            let telas = [ ...state.telas ];
            let telaAnterior = telas.pop();
            telaAnterior.dataHoraFim =  payload.timestamp;
            return {
                ...state,
                telas: [
                    ...telas,
                    telaAnterior
                ]
            };
        case TYPES.CONTINUAR_PROXIMO_PASSO:
            let proximoPasso = state.triagemAuditiva.passo + 1;
            if (payload.passo) {
                proximoPasso = payload.passo;
            }
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    passo: proximoPasso
                }
            }

        case TYPES.RESETAR_TESTE:
            return {
                ...state,
                triagemAuditiva: {
                    ...INITIAL_STATE.triagemAuditiva
                },
                telas: [],
                teste: {
                    ...INITIAL_STATE.teste
                }
            }

        case TYPES.TESTE_CONTINUAR_PROXIMO_PASSO:
            let ultimoConjuntoRespostas = state.teste.respostas[state.teste.respostas.length - 1];

            //Calcula o número de acertos
            let acertos = 0;
            if (ultimoConjuntoRespostas.primeiroDigito.toString() === ultimoConjuntoRespostas.primeiroDigitoSorteado.toString()) {
                acertos++;
            }

            if (ultimoConjuntoRespostas.segundoDigito.toString() === ultimoConjuntoRespostas.segundoDigitoSorteado.toString()) {
                acertos++;
            }

            if (ultimoConjuntoRespostas.terceiroDigito.toString() === ultimoConjuntoRespostas.terceiroDigitoSorteado.toString()) {
                acertos++;
            }

            ultimoConjuntoRespostas.acertos = acertos;

            let respostasUltimoConjunto = state.teste.respostas;
            respostasUltimoConjunto[respostasUltimoConjunto.length - 1] = ultimoConjuntoRespostas;
            return {
                ...state,
                teste: {
                    ...state.teste,
                    passo: state.teste.passo + 1,
                }
            }

        case TYPES.TESTE_INICIAR_PASSO:
            let problemasTeste = false;

            //Verifica se o último resultado do teste indica problemas com ambiente ruidoso ou volume sonoro
            if (state.teste.respostas[state.teste.respostas.length - 1]?.snr === 4
                && state.teste.respostas[state.teste.respostas.length - 1]?.acertos <= 2) {
                problemasTeste = true;
            }

            //Calcula próximo snr
            let snr = 0;

            //Começa sempre o teste com snr = 4
            //Passo 0 e 1   => lado esquerdo
            //Passo 24 e 25 => lado direito
            if (state.teste.passo === 0 || state.teste.passo === 1 ||
                state.teste.passo === 24 || state.teste.passo === 25) {
                snr = 4;
            } else {
                switch (state.teste.respostas[state.teste.respostas.length - 1].acertos) {
                    case 0:
                        snr = state.teste.respostas[state.teste.respostas.length - 1].snr + 4;
                        break;

                    case 1:
                        snr = state.teste.respostas[state.teste.respostas.length - 1].snr + 2;
                        break;

                    case 2:
                        snr = state.teste.respostas[state.teste.respostas.length - 1].snr - 2;
                        break;

                    case 3:
                        snr = state.teste.respostas[state.teste.respostas.length - 1].snr - 4;
                        break;
                }
            }

            //Verifica os limites que o snr pode obter atualmente
            if (snr > 4) {
                snr = 4;
            } else if (snr < -18) {
                snr = -18;
            }

            const primeiroDigitoSorteado = Math.floor(Math.random() * 10);

            let digito2Repetido = true;
            let segundoDigitoSorteado;
            while (digito2Repetido) {
                segundoDigitoSorteado = Math.floor(Math.random() * 10);
                if (segundoDigitoSorteado !== primeiroDigitoSorteado) {
                    digito2Repetido = false;
                }
            }

            let digito3Repetido = true;
            let terceiroDigitoSorteado;
            while (digito3Repetido) {
                terceiroDigitoSorteado = Math.floor(Math.random() * 10);
                if (terceiroDigitoSorteado !== primeiroDigitoSorteado && terceiroDigitoSorteado !== segundoDigitoSorteado) {
                    digito3Repetido = false;
                }
            }

            return {
                ...state,
                teste: {
                    ...state.teste,
                    problemasTeste: problemasTeste,
                    respostas: [
                        ...state.teste.respostas,
                        {
                            primeiroDigito: '',
                            primeiroDigitoSorteado: primeiroDigitoSorteado,
                            segundoDigito: '',
                            segundoDigitoSorteado: segundoDigitoSorteado,
                            terceiroDigito: '',
                            terceiroDigitoSorteado: terceiroDigitoSorteado,
                            snr: snr
                        }
                    ]
                }
            }

        case TYPES.TESTE_APAGAR_ULTIMO_DIGITO:
            let ultimaRespostaDigito = state.teste.respostas[state.teste.respostas.length - 1];

            if (ultimaRespostaDigito.terceiroDigito !== '') {
                ultimaRespostaDigito.terceiroDigito = '';
            } else if (ultimaRespostaDigito.segundoDigito !== '') {
                ultimaRespostaDigito.segundoDigito = '';
            } else if (ultimaRespostaDigito.primeiroDigito !== '') {
                ultimaRespostaDigito.primeiroDigito = '';
            }

            let respostasFormatadasUltimoDigito = state.teste.respostas;
            respostasFormatadasUltimoDigito[respostasFormatadasUltimoDigito.length - 1] = ultimaRespostaDigito;

            return {
                ...state,
                teste: {
                    ...state.teste,
                    respostas: respostasFormatadasUltimoDigito
                }
            }

        case TYPES.TESTE_INFORMAR_DIGITO:
            let ultimoPasso = state.teste.respostas[state.teste.respostas.length - 1];
            let digitoInformado = false;
            if (ultimoPasso.primeiroDigito === '') {
                digitoInformado = true;
                ultimoPasso.primeiroDigito = payload.digito;
            } else if (ultimoPasso.segundoDigito === '') {
                digitoInformado = true;
                ultimoPasso.segundoDigito = payload.digito;
            } else if (ultimoPasso.terceiroDigito === '') {
                digitoInformado = true;
                ultimoPasso.terceiroDigito = payload.digito;
            }

            if (digitoInformado) {
                let respostasFormatadas = state.teste.respostas;
                respostasFormatadas[respostasFormatadas.length - 1] = ultimoPasso;

                return {
                    ...state,
                    teste: {
                        ...state.teste,
                        respostas: respostasFormatadas
                    }
                }
            }
            return state;
            
        case TYPES.SET_TAB_ATUAL:
            return {
                ...state,
                tabAtual: payload
            }

        case TYPES.TESTE_SET_RESULTADO:
            return {
                ...state,
                teste: {
                    ...state.teste,
                    resultado: payload.resultado
                }
            }

        case TYPES.SET_RESULTADO_QUESTIONARIO:
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    resultadoQuestionario: payload.resultado
                }
            }

        case TYPES.SET_AUTORIZA_CONTATO:
            return {
                ...state,
                triagemAuditiva: {
                    ...state.triagemAuditiva,
                    autorizaContato: payload.autoriza
                }
            }

        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index
});