export default {
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    SET_USER: 'SET_USER',
    SET_TCLE_TIME: 'SET_TCLE_TIME',
    SET_RESPOSTA_QUESTIONARIO: 'SET_RESPOSTA_QUESTIONARIO',
    LOGOUT: 'LOGOUT',
    SET_MESSAGE: 'SET_MESSAGE',
    RESPONDER_PERGUNTA: 'RESPONDER_PERGUNTA',
    SET_RESULTADO_QUESTIONARIO: 'SET_RESULTADO_QUESTIONARIO',
    CONTINUAR_PROXIMO_PASSO: 'CONTINUAR_PROXIMO_PASSO',
    SET_TAB_ATUAL: 'SET_TAB_ATUAL',
    SET_ID: 'SET_ID',
    CONSIDERA_PROBLEMA_AUDICAO: 'CONSIDERA_PROBLEMA_AUDICAO',
    COMPREENDE_CONVERSA_AMBIENTES_RUIDOSOS: 'COMPREENDE_CONVERSA_AMBIENTES_RUIDOSOS',
    TIPO_FONE: 'TIPO_FONE',
    SET_AUTORIZA_CONTATO: 'SET_AUTORIZA_CONTATO',
    INICIAR_CONTAGEM_TEMPO_TELA: 'INICIAR_CONTAGEM_TEMPO_TELA',
    FINALIZAR_CONTAGEM_TEMPO_TELA: 'FINALIZAR_CONTAGEM_TEMPO_TELA',
    //CONSTANTES DO TESTE
    RESETAR_TESTE: 'RESETAR_TESTE',
    TESTE_CONTINUAR_PROXIMO_PASSO: 'TESTE_CONTINUAR_PROXIMO_PASSO',
    TESTE_INICIAR_PASSO: 'TESTE_INICIAR_PASSO',
    TESTE_INFORMAR_DIGITO: 'TESTE_INFORMAR_DIGITO',
    TESTE_APAGAR_ULTIMO_DIGITO: 'TESTE_APAGAR_ULTIMO_DIGITO',
    TESTE_SET_RESULTADO: 'TESTE_SET_RESULTADO'
};