module.exports = {
    anexar: 'Anexar',
    anexarArquivo: 'Anexar arquivos',
    anexos: 'Lista de anexos',
    ano: 'Ano de nascimento',
    atendimento: 'Em atendimento',
    atendimentoEncerrado: 'O seu atendimento foi encerrado.',
    buscandoCpf: `Aguarde. Buscando dados do seu CPF...`,
    chamadaVideo: 'Iniciar atendimento por vídeo',
    chat: 'Teleatendimento',
    cnesInvalido: cnes => `A instituição com o CNES número ${cnes} não existe ou não está habilitada para utilizar o módulo de teleatendimento.`,
    concluido: 'Concluído',
    concordo: 'Concordo',
    dadosPessoais: 'Dados pessoais',
    dia: 'Dia de nascimento',
    digiteMensagem: 'Digite sua mensagem...',
    discordo: 'Discordo',
    enviandoArquivo: 'Enviando arquivo...',
    enviandoCadastro: 'Enviando cadastro...',
    enviarMensagem: 'Enviar mensagem',
    espera: 'Em espera',
    fecharChamadaVideo: 'Fechar atendimento por vídeo',
    finalizarAtendimento: 'Finalizar atendimento',
    historico: 'Histórico do dia',
    historicoChat: 'Histórico do chat',
    inserirAnexo: 'Inserir anexo',
    jaOnline: 'Você já está conectado no módulo de Teleatendimento através de outra aba, navegador ou dispotivo.',
    menuAtendimento: 'Atendimento',
    mes: 'Mês de nascimento',
    observacao: 'Digite uma observação',
    preCadastro: 'Para ir ao teleatendimento você deve fazer este pequeno pré-cadastro.',
    preenchaCpf: 'Preencha o CPF para começar o teleatendimento.',
    salvando: 'Salvando termo de consentimento...',
    selecionarEmoji: 'Selecionar emoji',
    selecioneArquivo: 'Selecione um arquivo',
    selecioneInstituicao: 'Instituição para atendimento',
    selecioneUsuario: 'Selecione um usuário para iniciar ou continuar um atendimento.',
    teleatendimento: 'Teleatendimento',
    teleatendimentoPaciente: 'Serviço de teleatendimento a pacientes',
    termo: 'Termo de consentimento',
    textoAguarde: 'Aguarde, em breve você será atendido.',
    textoAtendenteConectado: 'Atendente conectado, por favor retire sua dúvida.',
    textoAtendimentoFinalizado: 'O atendente encerrou este atendimento.',
    usuariosParaAtendimento: 'Usuários para atendimento',
    validar: 'Validar',
    validarDados: 'Para validarmos seu cadastro, por favor digite os dados solicitados abaixo.'
}