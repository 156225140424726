import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttTranslate,
    SttLoading,
    SttButton,
    SttModal,
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SttButtonExternoAlofono from '../../../../componentes/geral/SttButtonExternoAlofono';
import SttButtonInternoAlofono from '../../../../componentes/geral/SttButtonInternoAlofono';
import SttBoxFalaAlofono from '../../../../componentes/geral/SttBoxFalaAlofono';
import Prancheta1 from '../../../../img/prancheta1.png';
import axios from 'axios';
import { getHeaders } from '../../../../request/index';
import themeSplashScreen from '../../../../themeSplashScreen.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { 
    continuarProximoPasso,
    setId,
    setQuestionario,
    setResultadoQuestionario,
    iniciarContagemTempoTela, 
    finalizarContagemTempoTela
} from '../../../../redux/actions';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    containerImagemIcone: {
        position: 'relative',
        marginTop: theme.spacing(4)
    },
    imagemPrancheta: {
        marginBottom: '-5px',
        maxWidth: theme.spacing(43)
    }
}));

const PreparandoTeste = (props) => {
    const {
        user, 
        strings, 
        continuarProximoPasso,
        tcleTime,
        triagemAuditiva,
        setId,
        setQuestionario,
        setResultadoQuestionario,
        iniciarContagemTempoTela,
        finalizarContagemTempoTela
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [modalAviso, setModalAviso] = useState(false);
    const [loading, setLoading] = useState(false);
    const [salvar, setSalvar] = useState(false);

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => {
            finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
        }
    }, []);

    useEffect(() => {
        if (salvar) {
            setLoading(true);
            let userAgent = null;
            if (navigator?.userAgent) {
                userAgent = navigator.userAgent;
            } else if (window?.navigator?.userAgent) {
                userAgent = window.navigator.userAgent
            }

            const ALOFONO_API_BASE_URL = global.gConfig.url_base_alofono;
            const dados = {
                paciente: user.idPaciente,
                problemaAudicao: triagemAuditiva.consideraProblemaAudicao,
                compreendeConversa: triagemAuditiva.compreendeConversaAmbientesRuidosos,
                tipoFone: 'I',
                userAgent,
                tcleTime
            };

            axios
                .post(`${ALOFONO_API_BASE_URL}/iniciar`, dados, { headers: getHeaders(), timeout: 8000 })
                .then((response) => {
                    const { data } = response;
                    setId(data.idTdr);
                    if (data.respostasQuestionario.length) {
                        const questionario = data.respostasQuestionario;
                        const REFERENCIA_RESULTADO = 7.3;
                        const total = questionario.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue));
                        const resultado = (total / questionario.length) >= REFERENCIA_RESULTADO;
                        setResultadoQuestionario(resultado);
                        setQuestionario(questionario);
                        continuarProximoPasso();
                    } else {
                        continuarProximoPasso();
                    }
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    setSalvar(false);
                    setModalAviso(true);
                });
        }
    }, [salvar]);
 
    return (
        <>
            <SttHidden smDown>
                <SttBoxAlofono $azul />
                <SttBoxAlofono style={{ paddingBottom: 0 }}>
                    <SttGridContainerAlofono style={{ width: '100%', height: '100%', alignItems: 'end' }}>
                        <SttGrid item container xs={12} md={5}>
                            <SttGrid item xs={12}>
                                <SttHeading variant="h4">{strings.textoIniciarTesteDigito}</SttHeading>
                            </SttGrid>
                            <SttGrid item xs={12} style={{ padding: '48px 0px 48px' }}>
                                <SttButtonExternoAlofono variant="contained" color="primary" nomarginleft="true" onClick={() => setSalvar(true)}>
                                    {strings.iniciarTeste}
                                </SttButtonExternoAlofono>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid item xs={12} md={3} container direction="column" style={{ alignItems: 'center' }}>
                            <SttBoxFalaAlofono fala={strings.textoNaoEntenderNumero} />
                            <div className={classes.containerImagemIcone}>
                                <img src={Prancheta1} className={classes.imagemPrancheta} />
                            </div>
                        </SttGrid>
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
                <SttBoxAlofono $azul />
            </SttHidden>
            <SttHidden mdUp>
                <SttBoxAlofono style={{ height: '60%' }}>
                    <SttGrid container spacing={2} style={{ alignItems: 'center' }}>
                        <SttGrid item xs={12} container alignItems="center" justifyContent="center" style={{ alignItems: 'end' }}>
                            <SttHeading variant="h4">{strings.textoIniciarTesteDigito}</SttHeading>
                        </SttGrid>

                        <SttGrid item xs={12}>
                            <SttBoxAlofono $cinzaClaro style={{ padding: isXs ? '8px' : '24px', textAlign: 'center' }}>
                                <SttHeading variant="h3">{strings.textoNaoEntenderNumero}</SttHeading>
                            </SttBoxAlofono>
                        </SttGrid>

                        <SttGrid item xs={12} container alignItems="flex-start" justifyContent="center">
                            <SttButtonInternoAlofono variant="contained" color="primary" nomarginleft="true" onClick={() => setSalvar(true)}>
                                {strings.iniciarTeste}
                            </SttButtonInternoAlofono>
                        </SttGrid>
                    </SttGrid>
                </SttBoxAlofono>
            </SttHidden>
            <SttLoading open={loading} />
            <ThemeProvider theme={themeSplashScreen}>
                <SttModal
                    title={strings.atencao}
                    open={modalAviso}
                    maxWidth="xs"
                    outModalClose={() => setModalAviso(false)}
                    iconClose={() => setModalAviso(false)}
                    fullWidth={true}
                    children={
                        <>
                            <SttGridContainerAlofono direction='column' style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                <SttGrid item xs={12}>
                                    <SttHeading variant="h6">{strings.avisoErroIniciar}</SttHeading>
                                    <SttHeading variant="h6">{strings.tentarNovamente}?</SttHeading>
                                </SttGrid>
                            </SttGridContainerAlofono>
                        </>
                    }
                    footer={
                        <div>
                            <SttButton variant="contained"
                                color="secondary"
                                onClick={() => {
                                    continuarProximoPasso(10);
                                }}>
                                {strings.nao}
                            </SttButton>
                            <SttButton variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setModalAviso(false);
                                    setSalvar(true);
                                }}>
                                {strings.sim}
                            </SttButton>
                        </div>
                    }
                />
            </ThemeProvider>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        triagemAuditiva: state.index.triagemAuditiva,
        tcleTime: state.index.tcleTime
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: (passo) => dispatch(continuarProximoPasso(passo)),
        setId: (id) => dispatch(setId(id)),
        setQuestionario: (resposta) => dispatch(setQuestionario(resposta)),
        setResultadoQuestionario: (resultado) => dispatch(setResultadoQuestionario(resultado)),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('PreparandoTeste')(PreparandoTeste));