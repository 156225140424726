import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import {
    SttGrid,
    SttHeading,
    SttLink,
    SttHidden,
    SttTranslate
} from '@stt-componentes/core';
import { setTabAtual } from '../../redux/actions/index';
import { ID_TABS } from '../../common/Constants';
import LogoAlofonoLateralBranco from '../../img/logoAlofonoLateralBranco.svg';

const useStyles = makeStyles(theme => ({
    containerPrincipal: {
        backgroundColor: '#1671B9',
        padding: `${theme.spacing(6)}px 0px ${theme.spacing(6)}px`,
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerPrincipalEscuro: {
        backgroundColor: '#0B4E83',
        padding: `0px 0px ${theme.spacing(6)}px`,
        alignItems: 'center',
        justifyContent: 'center'
    },
    link: {
        fontSize: '20px',
        color: '#FFFFFF'
    },
    paddingItens: {
        padding: `0px 0px ${theme.spacing(3)}px 0px`,
        '@media (min-width: 0px) and (max-width: 840px)': {
            padding: `0px 0px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
        }
    },
    boxExterno: {
        paddingBottom: '0px !important',
        '@media (min-width: 0px) and (max-width: 840px)': {
            paddingTop: `${theme.spacing(2)}px !important`
        }
    },
    logo: {
        height: '100%',
        width: '100%'
    }
}));

const StyledLink = withStyles(theme => ({
    root: {
        color: "#FFFFFF",
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: '150%',
        fontFamily: [
            'Roboto',
            'sans-serif',
        ].join(','),
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 0px) and (max-width: 840px)': {
            fontSize: '1rem'
        }
    }
}))(SttLink);

const StyledSttHeading = withStyles(theme => ({
    root: {
        color: "#FFFFFF",
        fontSize: theme.spacing(3),
        marginRight: theme.spacing(5),
    }
}))(SttHeading);

const Rodape = ({ strings, setTabAtual }) => {
    const classes = useStyles();

    return (
        <>
            <SttHidden smDown>
                <SttGrid container className={classes.containerPrincipal} >
                    <SttGrid container item xs={12} alignItems="center" justifyContent="center">
                        <SttGrid item xs={2}></SttGrid>
                        <SttGrid item xs={6}>
                            <SttHeading variant="h4" style={{ color: "#FFFFFF", }}>{strings.emCasoDuvidas}</SttHeading>
                            <SttHeading variant="h5" style={{ padding: '16px 0px 0px 0px', color: "#FFFFFF", }}>{strings.emailAlofono}</SttHeading>
                        </SttGrid>

                        <SttGrid item xs={2}>
                            <img src={LogoAlofonoLateralBranco} className={classes.logo} />
                        </SttGrid>
                        <SttGrid item xs={2}></SttGrid>
                    </SttGrid>
                </SttGrid>

                <SttGrid container className={classes.containerPrincipalEscuro} >
                    <SttGrid item xs={12} container justifyContent="center" alignItems="center" style={{ paddingTop: '96px', backgroundColor: '#0B4E83' }}>
                        <SttGrid item xs={2}></SttGrid>
                        <SttGrid item xs={2}>
                            <StyledLink onClick={() => setTabAtual(ID_TABS.INICIO)}>{strings.paginaInicial}</StyledLink>
                        </SttGrid>

                        <SttGrid item xs={3}>
                            <StyledLink onClick={() => setTabAtual(ID_TABS.SOBRE_TRIAGEM_AUDITIVA)}>{strings.sobreTriagemAuditiva}</StyledLink>
                        </SttGrid>

                        <SttGrid item xs={3}>
                            <StyledLink onClick={() => setTabAtual(ID_TABS.INICIAR_TRIAGEM_AUDITIVA)}>{strings.iniciarTriagemAuditiva}</StyledLink>
                        </SttGrid>
                    </SttGrid>
                </SttGrid>
            </SttHidden>

            <SttHidden mdUp>
                <SttGrid container className={classes.containerPrincipal}>
                    <SttGrid item xs={10}>
                        <StyledSttHeading variant="h5">{strings.emCasoDuvidas}</StyledSttHeading>
                    </SttGrid>

                    <SttGrid item xs={10} style={{ paddingTop: '16px', color: "#FFFFFF", }}>
                        <SttHeading variant="h4">{strings.emailAlofono}</SttHeading>
                    </SttGrid>

                    <SttGrid item container xs={12} style={{ paddingTop: '16px' }}>
                        <SttGrid item xs={1}></SttGrid>
                        <SttGrid item xs={4}>
                            <img src={LogoAlofonoLateralBranco} className={classes.logo} />
                        </SttGrid>
                    </SttGrid>
                </SttGrid>

                <SttGrid container className={classes.containerPrincipalEscuro} >
                    <SttGrid item xs={12} className={classes.paddingItens} style={{ paddingTop: '32px' }}>
                        <StyledLink onClick={() => setTabAtual(ID_TABS.INICIO)}>{strings.paginaInicial}</StyledLink>
                    </SttGrid>

                    <SttGrid item xs={12} className={classes.paddingItens}>
                        <StyledLink onClick={() => setTabAtual(ID_TABS.SOBRE_TRIAGEM_AUDITIVA)}>{strings.sobreTriagemAuditiva}</StyledLink>
                    </SttGrid>

                    <SttGrid item xs={12} className={classes.paddingItens}>
                        <StyledLink onClick={() => setTabAtual(ID_TABS.INICIAR_TRIAGEM_AUDITIVA)}>{strings.iniciarTriagemAuditiva}</StyledLink>
                    </SttGrid>
                </SttGrid>
            </SttHidden>
        </>

    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTabAtual: (idTabAtual) => dispatch(setTabAtual(idTabAtual))
    };
};

export default connect(null, mapDispatchToProps)(SttTranslate('Rodape')(Rodape));