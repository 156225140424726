import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { continuarProximoPasso, iniciarContagemTempoTela, finalizarContagemTempoTela } from '../../../redux/actions';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttTranslate
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../componentes/geral/SttGridContainerAlofono';
import SttFeedbackAlofono from '../../../componentes/geral/SttFeedbackAlofono';
import SttBoxUnidadeSaudeAlofono from '../../../componentes/interno/triagemAuditiva/componentes/SttBoxUnidadeSaudeAlofono';
import SttButtonInternoAlofono from '../../../componentes/geral/SttButtonInternoAlofono';

const useStyles = makeStyles(theme => ({
    opcoes: {}
}));

const Resultado = ({ user, strings, triagemAuditiva, continuarProximoPasso, teste, telas, iniciarContagemTempoTela, finalizarContagemTempoTela }) => { 
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [boaAudicao, setBoaAudicao] = useState(undefined);
    const [salvar, setSalvar] = useState(false);

    const finalizar = () => {
        finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
        setSalvar(true);
    }
    
    useEffect(() => {
        if (salvar) {
            const ALOFONO_API_BASE_URL = global.gConfig.url_base_alofono;
            const dados = { 
                idTdr: triagemAuditiva.id,
                telas: telas
            };
            
            axios
                .post(`${ALOFONO_API_BASE_URL}/finalizar`, dados, { headers: getHeaders() })
                .then((response) => {})
                .catch(err => console.error(err))
                .finally(() => continuarProximoPasso());
        }
    }, [salvar]);

    useEffect(() => {
        setBoaAudicao(teste.resultado);
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono spacing={2}>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.resultado}</SttHeading>
                        </SttHidden>
                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.resultado}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <SttBoxAlofono $semMargemInferior={true} style={{paddingBottom: 0}}>
                <SttGridContainerAlofono spacing={isXs ? 0 : 5} justifyContent="center" alignItems="center">
                    <SttGrid item xs={10} md={6} className={classes.opcoes}>
                        {
                            boaAudicao ? 
                                <SttFeedbackAlofono $verde text={`${user.nome}, ${strings.feedbackVerdeTriagem}`} />
                            :
                                <SttFeedbackAlofono $laranja text={`${user.nome}, ${strings.feedbackLaranjaTriagem}`} />
                        }
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            {
                !boaAudicao && 
                <SttBoxAlofono $semMargemInferior={true}>
                    <SttGridContainerAlofono spacing={isXs ? 0 : 5} justifyContent="center" alignItems="center">
                        <SttGrid item xs={12} md={6} className={classes.opcoes}>
                            <SttBoxUnidadeSaudeAlofono>
                                <SttHeading variant="h4">{strings.mensagemUnidadeSaude}</SttHeading>
                            </SttBoxUnidadeSaudeAlofono> 
                        </SttGrid>
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
            }

            <SttBoxAlofono $semMargemInferior={true} style={{paddingTop: isXs ? 0 : '48px'}}>
                <SttGridContainerAlofono style={{textAlign: 'center', paddingBottom: isXs ? '24px' : '48px'}}>
                    <SttGrid item xs={10} md={4} className={classes.opcoes}>
                        <SttButtonInternoAlofono 
                            className={classes.botao}
                            variant="contained" 
                            color="primary" 
                            nomarginleft="true" 
                            onClick={finalizar}
                        >
                            {strings.finalizarTriagem}
                        </SttButtonInternoAlofono>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        triagemAuditiva: state.index.triagemAuditiva,
        teste: state.index.teste,
        telas: state.index.telas
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: () => dispatch(continuarProximoPasso()),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Triagem')(Resultado));
