import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttPaper,
    SttDivider,
    SttRadioButton,
    SttModal,
    SttButton,
    SttFormHelperText,
    SttTranslate,
    SttLoading
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SttButtonExternoAlofono from '../../../../componentes/geral/SttButtonExternoAlofono';
import { continuarProximoPasso, iniciarContagemTempoTela, finalizarContagemTempoTela } from '../../../../redux/actions';
import ClearLogo from '../../../../img/clear.svg';
import DoneLogo from '../../../../img/done.svg';
import themeSplashScreen from '../../../../themeSplashScreen.js';
import { ThemeProvider } from '@material-ui/core/styles';
import SttAudioPlayerAlofono from '../../../../componentes/geral/AudioPlayer/index';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    secaoBotaoContinuar: {
        padding: '0px !important',
        '@media (min-width: 0px) and (max-width: 840px)': {
            marginBottom: theme.spacing(6)
        }
    },
    containerGeral: {
        margin: 0
    }
}));

const PreparandoTeste = ({ strings, continuarProximoPasso, triagemAuditiva, iniciarContagemTempoTela, finalizarContagemTempoTela }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [resposta, setResposta] = useState(null);
    const [modalAviso, setModalAviso] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const [exibirCarregandoArquivos, setExibirCarregandoArquivos] = useState(false);

    const tratarResposta = () => {
        setSubmitCount(submitCount + 1);

        if (resposta === null) {
            return;
        }

        if (resposta === 'N') {
            setModalAviso(true);
            return;
        }

        if (!global.audioConfig.carregamentoFinalizado) {
            setExibirCarregandoArquivos(true);
            verificarDownloadArquivos();
            return;
        }

        continuarProximoPasso();
    }

    const verificarDownloadArquivos = () => {
        setTimeout(() => {
            if (global.audioConfig.carregamentoFinalizado) {
                setExibirCarregandoArquivos(false);
                continuarProximoPasso();
                return;
            }
            verificarDownloadArquivos();
        }, 1000);
    }

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.preparandoTeste}</SttHeading>
                        </SttHidden>

                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.preparandoTeste}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
            <SttBoxAlofono>
                <SttGridContainerAlofono justifyContent="center" alignItems="center">
                    <SttGrid item xs={12} md={6}>
                        <SttPaper elevation={2}>
                            <SttGridContainerAlofono spacing={3} className={classes.containerGeral}>
                                <SttGrid item xs={12}>
                                    <SttHeading variant="h4" style={{ textAlign: 'center' }}>{strings.acioneBotaoOuvir}</SttHeading>
                                </SttGrid>

                                <SttGrid item xs={12} md={8} container justifyContent="center" alignItems="center">
                                    <SttAudioPlayerAlofono audioTeste={true} />
                                </SttGrid>

                                <SttGrid item xs={12}>
                                    <SttHeading variant="h5" style={{ textAlign: 'center' }}>{strings.voceConsegueOuvirSom}</SttHeading>
                                </SttGrid>

                                <SttGrid item xs={12}>
                                    <SttGrid container alignItems="center">
                                        <SttGrid item container xs={2} style={{ alignItems: 'center' }}>
                                            <img src={DoneLogo} />
                                        </SttGrid>
                                        <SttGrid item container xs={8} style={{ alignItems: 'center' }}>
                                            <SttHeading variant="h5">{strings.sim}</SttHeading>
                                        </SttGrid>
                                        <SttGrid item container xs={2} style={{ justifyContent: 'end' }}>
                                            <SttRadioButton
                                                type="radio"
                                                value={'S'}
                                                color="primary"
                                                checked={resposta === 'S'}
                                                onChange={event => {
                                                    if (event?.target?.value) {
                                                        setResposta('S');
                                                    }
                                                }}
                                            />
                                        </SttGrid>
                                    </SttGrid>
                                </SttGrid>

                                <SttGrid item xs={12} style={{ width: '100%' }}>
                                    <SttDivider />
                                </SttGrid>

                                <SttGrid item xs={12}>
                                    <SttGrid container alignItems="center">
                                        <SttGrid item container xs={2} style={{ alignItems: 'center' }}>
                                            <img src={ClearLogo} />
                                        </SttGrid>
                                        <SttGrid item container xs={8} style={{ alignItems: 'center' }}>
                                            <SttHeading variant="h5">{strings.nao}</SttHeading>
                                        </SttGrid>
                                        <SttGrid item container xs={2} style={{ justifyContent: 'end' }}>
                                            <SttRadioButton
                                                type="radio"
                                                value={'N'}
                                                color="primary"
                                                checked={resposta === 'N'}
                                                onChange={event => {
                                                    if (event?.target?.value) {
                                                        setResposta('N');
                                                    }
                                                }}
                                            />
                                        </SttGrid>
                                    </SttGrid>
                                </SttGrid>
                            </SttGridContainerAlofono>
                        </SttPaper>
                        {
                            submitCount > 0 && resposta === null &&
                            <SttFormHelperText error>
                                {strings.perguntaObrigatoria}
                            </SttFormHelperText>
                        }
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
            <SttHidden smDown>
                <SttBoxAlofono style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <SttGridContainerAlofono direction='column' style={{ marginBottom: '24px', alignItems: 'center' }}>
                        <SttGrid item xs={12} md={6}>
                            <SttBoxAlofono $cinzaClaro style={{ padding: isXs ? '8px' : '24px', textAlign: 'center' }}>
                                <SttHeading variant="h5">{strings.reguleVolumeFone}</SttHeading>
                            </SttBoxAlofono>
                        </SttGrid>
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
            </SttHidden>
            <SttHidden mdUp>
                <SttBoxAlofono style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <SttGridContainerAlofono direction='column' style={{ marginBottom: '24px', height: '100%', alignItems: 'center' }}>
                        <SttGrid item xs={12} md={6}>
                            <SttBoxAlofono $cinzaClaro style={{ padding: isXs ? '8px' : '24px', textAlign: 'center' }}>
                                <SttHeading variant="h5">{strings.reguleVolumeFone}</SttHeading>
                            </SttBoxAlofono>
                        </SttGrid>
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
            </SttHidden>

            <SttLoading
                open={exibirCarregandoArquivos}
                text={strings.aguardeAudioDownload}
            />

            {/* Container para visualização do botão de continuar */}
            <SttBoxAlofono className={classes.secaoBotaoContinuar}>
                <SttGridContainerAlofono spacing={isXs ? 0 : 3} direction='column' style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                    <SttGrid item xs={12}>
                        <SttButtonExternoAlofono style={{ marginBottom: isXs ? '24px' : '0px' }} variant="contained" color="primary" nomarginleft="true" onClick={() => tratarResposta()}>
                            {strings.continuar}
                        </SttButtonExternoAlofono>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <ThemeProvider theme={themeSplashScreen}>
                <SttModal
                    title={strings.modalProblemasSomTitulo}
                    open={modalAviso}
                    maxWidth="xs"
                    outModalClose={() => setModalAviso(false)}
                    iconClose={() => setModalAviso(false)}
                    fullWidth={true}
                    children={
                        <>
                            <SttGridContainerAlofono direction='column' style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                <SttGrid item xs={12}>
                                    <SttHeading variant="h6">{strings.modalProblemasSomTexto}</SttHeading>
                                    <SttHeading variant="h6">{strings.modalProblemasSomTextoA}</SttHeading>
                                    <SttHeading variant="h6">{strings.modalProblemasSomTextoB}</SttHeading>
                                    <SttHeading variant="h6">{strings.modalProblemasSomTextoC}</SttHeading>
                                </SttGrid>
                            </SttGridContainerAlofono>
                        </>
                    }
                    footer={
                        <div>
                            <SttButton variant="contained"
                                color="secondary"
                                onClick={() => setModalAviso(false)}>
                                {strings.fechar}
                            </SttButton>
                        </div>
                    }
                />
            </ThemeProvider>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        triagemAuditiva: state.index.triagemAuditiva
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: () => dispatch(continuarProximoPasso()),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('PreparandoTeste')(PreparandoTeste));