import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import {
    setAuthenticated as setAuthenticatedAction,
    setUser as setUserAction,
    setTcleTime as setTcleTimeAction
} from '../../redux/actions';
import { Routes, Route } from 'react-router-dom';
import Menu from '../../componentes/interno/menu';
import { SttCircularProgress } from '@stt-componentes/core';
import { PERMISSOES } from '../../common/Constants';
import Erro401 from '../erro/401';
import ProtectedRoute from './protectedRoute';
import { SttCacheManager } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import TelaBoasVindas from '../../componentes/interno/telaBoasVindas';
import TriagemAuditiva from '../../componentes/interno/triagemAuditiva';
import { useQuery } from '../../hooks/index';
import initFetch from '../../fetchAudios';

const useStyles = makeStyles(theme => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));


const Interno = ({ isAuthenticated, setAuthenticated, setUser, setTcleTime }) => {
    const classes = useStyles();

    const query = useQuery();
    const tcleTime = query.get('tcle_time') || null;

    useEffect(() => {
        setTcleTime(tcleTime);
    }, []);

    return (
        <>
            {
                isAuthenticated
                    ?
                    <>
                        <Menu />
                        <Routes>
                            <Route path="/nao-autorizado" element={<Erro401 />} />
                            <Route path='/inicio' element={<ProtectedRoute component={TelaBoasVindas} permissao={PERMISSOES.ALOFONO} />} />
                            <Route path='/sobreTriagemAuditiva' element={<ProtectedRoute component={TelaBoasVindas} permissao={PERMISSOES.ALOFONO} />} />
                            <Route path='/iniciarTriagemAuditiva' element={<ProtectedRoute component={TriagemAuditiva} permissao={PERMISSOES.ALOFONO} />} />
                        </Routes>
                    </>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig}
                            redirectUrl={global.gConfig.url_redirect_cadastro_alofono}
                            onSync={loaded => {
                                const user = Cache.getUserInfo();
                                setUser(user);
                                setAuthenticated(loaded);

                                initFetch();
                            }} hasExternalToken={true} />
                    </>

            }
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.index.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
        setUser: (user) => dispatch(setUserAction(user)),
        setTcleTime: (tcleTime) => dispatch(setTcleTimeAction(tcleTime))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);