import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import moment from 'moment';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttTranslate
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import { continuarProximoPasso, iniciarContagemTempoTela, finalizarContagemTempoTela } from '../../../../redux/actions';
import TecladoNumerico from './componentes/tecladoNumerico';
import BoxInputPilula from './componentes/boxInputPilula';
import { LinearProgress } from '@material-ui/core/index';
import { testeApagarUltimoDigito, testeContinuarProximoPasso, testeIniciarPasso, testeInformarDigito } from '../../../../redux/actions/teste';
import { NUMERO_ETAPAS_TESTE } from '../../../../common/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SttAudioPlayerAlofono from '../../../../componentes/geral/AudioPlayer/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const TOAST_ID = 'uniqueToast';

const useStyles = makeStyles(theme => ({
    containerAzulTeclado: {
        marginTop: theme.spacing(6),
        '@media (min-width: 0px) and (max-width: 840px)': {
            marginTop: 0
        }
    }
}));

const Teste = ({ 
    strings, 
    teste, 
    triagemAuditiva, 
    testeApagarUltimoDigito, 
    testeContinuarProximoPasso, 
    testeIniciarPasso, 
    testeInformarDigito, 
    continuarProximoPasso, 
    iniciarContagemTempoTela, 
    finalizarContagemTempoTela 
}) => {
    const classes = useStyles();
    const [audios, setAudios] = useState([]);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [resetarNovoPasso, setResetarNovoPasso] = useState(false);
    const refLinearProgress = useRef();

    //Caso o passo mude 
    useEffect(() => {
        setResetarNovoPasso(false);
        if (teste.respostas.length < NUMERO_ETAPAS_TESTE) {
            testeIniciarPasso();
        }
    }, [teste.passo]);

    useEffect(() => {
        //Setar os áudios a partir dos dígitos gerados
        if (teste.respostas[teste.passo]) {
            const tresp = teste.respostas[teste.passo];

            let lado = 'E';
            if (teste.passo >= 24) {
                lado = 'D';
            }

            setAudios([
                global.audioConfig[tresp.snr][`${tresp.primeiroDigitoSorteado}${lado}`],
                global.audioConfig[tresp.snr][`${tresp.segundoDigitoSorteado}${lado}`],
                global.audioConfig[tresp.snr][`${tresp.terceiroDigitoSorteado}${lado}`]
            ]);
            setResetarNovoPasso(true);
        }
    }, [teste]);

    useEffect(() => {
        //Tratar mudança de passo
        if (teste.respostas[teste.passo]?.terceiroDigito && teste.respostas[teste.passo]?.terceiroDigito !== '') {
            let mensagem = strings.carregandoProximoPasso;
            if (teste.passo === NUMERO_ETAPAS_TESTE - 1) {
                mensagem = strings.carregandoResultados;
            }
            toast.info(mensagem, {
                toastId: TOAST_ID,
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                pauseOnFocusLoss: false,
                onClose: () => {
                    if (teste.respostas[teste.passo]?.terceiroDigito !== '') {
                        if (teste.passo === NUMERO_ETAPAS_TESTE - 1) {
                            continuarProximoPasso();
                            return;
                        }
                        testeContinuarProximoPasso();
                    }
                }
            });
        }
    }, [teste]);

    useEffect(() => {
        if(refLinearProgress?.current) {
            setTimeout(() => {
                refLinearProgress.current.scrollIntoView();
            }, 0);
        }
    }, [refLinearProgress]);

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    const onClickDigito = (digitoPressionado) => {
        testeInformarDigito(digitoPressionado);
    }

    const onClickApagarDigito = () => {
        toast.update(TOAST_ID, { onClose: null });
        toast.dismiss();
        testeApagarUltimoDigito();
    }

    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.testeDigitoRuido}</SttHeading>
                        </SttHidden>
                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.testeDigitoRuido}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
            <SttGridContainerAlofono justifyContent="center" alignItems="center" className={classes.containerAzulTeclado}>
                <SttGrid item xs={12} md={8}>
                    <SttBoxAlofono $azul={isXs ? false : true} $azulClaro={isXs ? true : false}>
                        <SttGrid container justifyContent="center" alignItems="center" spacing={isXs ? 1 : 5}>
                            <SttGrid item xs={12} container justifyContent="center" alignItems="center">
                                <SttHeading variant="h4">{strings.acioneBotaoOuvir}</SttHeading>
                            </SttGrid>
                            {
                                teste?.problemasTeste &&
                                <SttGrid item xs={12} container justifyContent="center" alignItems="center">
                                    <SttGridContainerAlofono direction='column' style={{ alignItems: 'center' }}>
                                        <SttGrid item xs={12} md={10}>
                                            <SttBoxAlofono $cinzaClaro style={{ padding: isXs ? '8px' : '24px', textAlign: 'center' }}>
                                                <SttHeading variant="h5">{strings.verificarAmbienteTeste}</SttHeading>
                                            </SttBoxAlofono>
                                        </SttGrid>
                                    </SttGridContainerAlofono>
                                </SttGrid>
                            }
                            {
                                audios.length > 0 && resetarNovoPasso &&
                                <SttGrid item xs={12} md={6} container justifyContent="center" alignItems="center">
                                    <SttAudioPlayerAlofono
                                        audios={audios}
                                        passo={teste.passo}
                                    />
                                </SttGrid>
                            }
                            <SttGrid item xs={10} style={{paddingTop: isXs ? '16px' : '24px', paddingBottom: isXs ? '16px' : '24px'}}>
                                <LinearProgress variant="determinate" value={(teste.passo * 100) / NUMERO_ETAPAS_TESTE} />
                            </SttGrid>
                            <SttGrid item xs={12}>
                                <BoxInputPilula passo={teste.passo} />
                            </SttGrid>
                            <SttGrid item xs={12} style={{paddingTop: isXs ? '16px' : '24px', paddingBottom: isXs ? '16px' : '24px'}}>
                                <TecladoNumerico onClickDigito={onClickDigito} onClickApagarDigito={onClickApagarDigito} />
                            </SttGrid>
                            
                        </SttGrid>
                    </SttBoxAlofono>
                </SttGrid>
                
            </SttGridContainerAlofono>
            <div ref={refLinearProgress}/>

            <ToastContainer />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        teste: state.index.teste,
        triagemAuditiva: state.index.triagemAuditiva
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: () => dispatch(continuarProximoPasso()),
        testeContinuarProximoPasso: () => dispatch(testeContinuarProximoPasso()),
        testeIniciarPasso: () => dispatch(testeIniciarPasso()),
        testeInformarDigito: (digito) => dispatch(testeInformarDigito(digito)),
        testeApagarUltimoDigito: () => dispatch(testeApagarUltimoDigito()),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Teste')(Teste));