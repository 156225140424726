import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttFormHelperText,
    SttTranslate
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SttBoxOpcoes from './componentes/SttBoxOpcoes';
import Prancheta1 from '../../../../img/prancheta1.png';
import SttButtonExternoAlofono from '../../../../componentes/geral/SttButtonExternoAlofono';
import SttBoxFalaAlofono from '../../../../componentes/geral/SttBoxFalaAlofono';
import SttButtonInternoAlofono from '../../../../componentes/geral/SttButtonInternoAlofono';
import {
    continuarProximoPasso,
    consideraProblemaAudicao,
    compreendeConversaAmbientesRuidosos,
    iniciarContagemTempoTela,
    finalizarContagemTempoTela
} from '../../../../redux/actions';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    secaoLembrarLocalSilencioso: {
        padding: '0px !important',
        '@media (min-width: 0px) and (max-width: 840px)': {
            marginBottom: theme.spacing(3)
        }
    },
    imagemPrancheta: {
        maxWidth: theme.spacing(43),
        marginTop: theme.spacing(4)
    },
    tituloConsideraProblemaAudicao: {
        '@media (min-width: 0px) and (max-width: 840px)': {
            marginBottom: theme.spacing(2)
        }
    },
    balaoLembrarLocalSilencioso: {
        '@media (min-width: 0px) and (max-width: 840px)': {
            marginBottom: theme.spacing(2)
        }
    }
}));

const ConfiguracaoDispositivo = ({
    strings,
    triagemAuditiva,
    continuarProximoPasso,
    consideraProblemaAudicao,
    compreendeConversaAmbientesRuidosos,
    iniciarContagemTempoTela,
    finalizarContagemTempoTela
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [submitCount, setSubmitCount] = useState(0);

    const iniciarTdr = () => {
        setSubmitCount(submitCount + 1);
        if (triagemAuditiva.consideraProblemaAudicao === null || triagemAuditiva.compreendeConversaAmbientesRuidosos === null) {
            return;
        }

        continuarProximoPasso();
    }

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);


    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.vamosIniciar}</SttHeading>
                        </SttHidden>

                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.vamosIniciar}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <SttBoxAlofono>
                <SttGridContainerAlofono spacing={isXs ? 0 : 2} justifyContent="center" alignItems="center">
                    <SttGrid item xs={12} md={4} className={classes.tituloConsideraProblemaAudicao}>
                        <SttBoxOpcoes titulo={strings.tituloConsideraProblemaAudicao} strings={strings} callback={consideraProblemaAudicao} />
                        {
                            submitCount > 0 && triagemAuditiva.consideraProblemaAudicao === null &&
                            <SttFormHelperText error>
                                {strings.perguntaObrigatoria}
                            </SttFormHelperText>
                        }
                    </SttGrid>

                    <SttGrid item xs={12} md={4}>
                        <SttBoxOpcoes titulo={strings.tituloCompreendeBemConversaAmbientesRuidosos} strings={strings} callback={compreendeConversaAmbientesRuidosos} />
                        {
                            submitCount > 0 && triagemAuditiva.compreendeConversaAmbientesRuidosos === null &&
                            <SttFormHelperText error>
                                {strings.perguntaObrigatoria}
                            </SttFormHelperText>
                        }
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            {/* Container para visualização do boneco com botão de continuar */}
            <SttHidden smDown>
                <SttBoxAlofono style={{ padding: '0px', paddingTop: '24px' }}>
                    <SttGridContainerAlofono style={{ alignItems: 'flex-start' }}>
                        <SttGrid item container xs={2}/>
                        <SttGrid item container xs={3}>
                            <SttGrid item container xs={12} direction="column">
                                <SttBoxFalaAlofono fala={strings.balaoIniciarConfiguracao} />
                                <img src={Prancheta1} className={classes.imagemPrancheta} />
                            </SttGrid>
                        </SttGrid>
                        <SttGrid item container xs={2} alignItems="center" justifyContent="center">
                            <SttButtonExternoAlofono variant="contained" color="primary" nomarginleft="true" onClick={iniciarTdr}>
                                {strings.continuar}
                            </SttButtonExternoAlofono>
                        </SttGrid>
                        <SttGrid item container xs={5} />
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
            </SttHidden>

            <SttHidden mdUp>
                <SttBoxAlofono className={classes.secaoLembrarLocalSilencioso}>
                    <SttGridContainerAlofono direction='column' style={{ alignItems: 'center' }}>
                        <SttGrid item xs={11} className={classes.balaoLembrarLocalSilencioso}>
                            <SttBoxAlofono $cinzaClaro style={{ padding: isXs ? '8px' : '24px' }}>
                                <SttHeading variant="h4" style={{ textAlign: 'center' }}>{strings.balaoIniciarConfiguracao}</SttHeading>
                            </SttBoxAlofono>
                        </SttGrid>

                        <SttGrid item xs={12} className={classes.secaoLembrarLocalSilencioso}>
                            <SttButtonInternoAlofono variant="contained" color="primary" nomarginleft="true" onClick={iniciarTdr}>
                                {strings.continuar}
                            </SttButtonInternoAlofono>
                        </SttGrid>
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
            </SttHidden>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        triagemAuditiva: state.index.triagemAuditiva,
        tcleTime: state.index.tcleTime
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: () => dispatch(continuarProximoPasso()),
        consideraProblemaAudicao: (resposta) => dispatch(consideraProblemaAudicao(resposta)),
        compreendeConversaAmbientesRuidosos: (resposta) => dispatch(compreendeConversaAmbientesRuidosos(resposta)),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('ConfiguracaoDispositivo')(ConfiguracaoDispositivo));