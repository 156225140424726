import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styled from 'styled-components';
import { ThemeProvider } from "styled-components";
import theme from '../../../../../theme';
import { SttPaper, SttBox } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 0,
        borderRadius: '8px'
    }
}));

const StyledSttBox = styled(SttBox)`
    text-align: center;
    padding: ${props => `${props.theme.spacing(5)}px`};
    border-radius: 8px;
    @media (max-width: 840px) {
        padding: ${props =>
            `${props.theme.spacing(2)}px`
        };
    };    
`;

const SttBoxIniciarQuestionario = (props) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <SttPaper elevation={2} className={classes.paper}>
                <StyledSttBox
                    {...props}
                />
            </SttPaper>
        </ThemeProvider>
    );
}

export default SttBoxIniciarQuestionario;