import React, { useState } from 'react';
import {
    SttGrid,
    SttHeading,
    SttPaper,
    SttDivider,
    SttRadioButton
} from '@stt-componentes/core';
import SttGridContainerAlofono from '../../../../../componentes/geral/SttGridContainerAlofono';
import { makeStyles } from "@material-ui/core/styles";
import ClearLogo from '../../../../../img/clear.svg';
import DoneLogo from '../../../../../img/done.svg';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(4)
    },
    containerGeral: {
        margin: 0,
        hyphens: 'auto'
    }
}));

const SttBoxOpcoes = ({ titulo, strings, callback }) => {
    const classes = useStyles();
    const [resposta, setResposta] = useState(false);

    return (
        <SttPaper elevation={2} className={classes.paper}>
            <SttGridContainerAlofono spacing={3} className={classes.containerGeral}>
                <SttGrid item xs={12}>
                    <SttHeading variant="h5">{titulo}</SttHeading>
                </SttGrid>

                <SttGrid item xs={12}>
                    <SttGrid container alignItems="center">
                        <SttGrid item container xs={3} md={2} style={{ alignItems: 'center' }}>
                            <img src={DoneLogo} />
                        </SttGrid>
                        <SttGrid item container xs={7} md={8} style={{ alignItems: 'center' }}>
                            <SttHeading variant="h5">{strings.sim}</SttHeading>
                        </SttGrid>
                        <SttGrid item container xs={2} md={2} style={{ justifyContent: 'end' }}>
                            <SttRadioButton
                                type="radio"
                                value={'S'}
                                color="primary"
                                checked={resposta === 'S'}
                                onChange={event => {
                                    if (event?.target?.value) {
                                        setResposta('S');
                                        callback(true);
                                    }
                                }}
                            />
                        </SttGrid>
                    </SttGrid>
                </SttGrid>

                <SttGrid item xs={12} style={{ width: '100%' }}>
                    <SttDivider />
                </SttGrid>

                <SttGrid item xs={12}>
                    <SttGrid container alignItems="center">
                        <SttGrid item container xs={3} md={2} style={{ alignItems: 'center' }}>
                            <img src={ClearLogo} />
                        </SttGrid>
                        <SttGrid item container xs={7} md={8} style={{ alignItems: 'center' }}>
                            <SttHeading variant="h5">{strings.nao}</SttHeading>
                        </SttGrid>
                        <SttGrid item container xs={2} md={2} style={{ justifyContent: 'end' }}>
                            <SttRadioButton
                                type="radio"
                                value={'N'}
                                color="primary"
                                checked={resposta === 'N'}
                                onChange={event => {
                                    if (event?.target?.value) {
                                        setResposta('N');
                                        callback(false);
                                    }
                                }}
                            />
                        </SttGrid>
                    </SttGrid>
                </SttGrid>
            </SttGridContainerAlofono>
        </SttPaper>

    );
}
export default SttBoxOpcoes;