import {
    isChrome,
    isChromium,
    isFirefox,
    isSafari,
    isMobileSafari,
    osVersion,
    browserVersion,
    isAndroid,
    isIOS
} from 'react-device-detect';

const ANDROID_MIN_VERSION = 10;
const IOS_MIN_VERSION = 13;
const CHROME_MIN_VERSION = 100;
const FIREFOX_MIN_VERSION = 100;
const SAFARI_MIN_VERSION = 15;

const verificacaoBrowserSO = (strings) => {
    let mensagens = [];
    if (!isChrome && !isChromium && !isFirefox && !isSafari && !isMobileSafari) {
        mensagens.push(strings.browserIncompativel);
    }

    if (isAndroid && parseInt(osVersion) < ANDROID_MIN_VERSION) {
        mensagens.push(strings.androidAntigo);
    } else if (isIOS && parseInt(osVersion) < IOS_MIN_VERSION) {
        mensagens.push(strings.iosAntigo);
    }

    if ((isChrome || isChromium) && parseInt(browserVersion) < CHROME_MIN_VERSION) {
        mensagens.push(strings.chromeAntigo);
    } else if (isFirefox && parseInt(browserVersion) < FIREFOX_MIN_VERSION) {
        mensagens.push(strings.firefoxAntigo);
    } else if ((isSafari || isMobileSafari) && parseInt(browserVersion) < SAFARI_MIN_VERSION) {
        mensagens.push(strings.safariAntigo);
    }

    return mensagens;
}

export default { verificacaoBrowserSO };