import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttHeading
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    bolha: {
        height: 'auto',
        width: theme.spacing(35),
        border: '1px solid #1671B9',
        borderRadius: theme.spacing(1),
        textAlign: 'center',
        color: '#1671B9',
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
    },
    downArrow: {
        transform: 'rotate(45deg)',
        '-webkit-transform': 'rotate(45deg)',
        border: 'solid #1671B9',
        borderWidth: '0 1px 1px 0',
        padding: '8px',
        width: 0,
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
        borderRadius: '0 0 6px 0',
        position: 'absolute'
    }
}));

const SttBoxFalaAlofono = ({ fala }) => {
    const classes = useStyles();

    return (
        <div className={classes.bolha}>
            <SttHeading variant="h6">{fala}</SttHeading>
            <i className={classes.downArrow}></i>
        </div>
    );
}
export default SttBoxFalaAlofono;