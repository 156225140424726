import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { connect } from 'react-redux';
import moment from 'moment';
import { continuarProximoPasso, iniciarContagemTempoTela, finalizarContagemTempoTela } from '../../../../redux/actions'
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttTranslate
} from '@stt-componentes/core';

import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import SttBoxPergunta from '../../../../componentes/interno/triagemAuditiva/questionario/componentes/SttBoxPergunta';
import SttButtonInternoAlofono from '../../../../componentes/geral/SttButtonInternoAlofono';

const useStyles = makeStyles(theme => ({
    conteinerBotao: {
        textAlign: 'center'
    }
}));

const Questionario = ({ strings, triagemAuditiva, continuarProximoPasso, iniciarContagemTempoTela, finalizarContagemTempoTela }) => { 
    const classes = useStyles();
    const [perguntas, setPerguntas] = useState([]);
    const [finalizado, setFinalizado] = useState(false);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    const { questionario } = triagemAuditiva;

    useEffect(() => {
        let arrayPerguntas = [];
        for (let i = 1; i <= 5; i++) {
            arrayPerguntas.push({ titulo: `${strings.Questionario.tituloPergunta} ${i}`, descricao: strings.Questionario[`descritivoPergunta_${i}`] });
        }
        setPerguntas(arrayPerguntas);
    }, []);

    useEffect(() => {
        if (questionario) {
            const pendente = questionario.some(p => Number.isNaN(parseInt(p)));
            setFinalizado(!pendente);
        }
    }, [questionario]);

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    const responder = () => {
        const ALOFONO_API_BASE_URL = global.gConfig.url_base_alofono;
        const dados = { idTdr: triagemAuditiva.id, questionario };
        
        axios
            .post(`${ALOFONO_API_BASE_URL}/questionario`, dados, { headers: getHeaders() })
            .then((response) => {})
            .catch(err => console.error(err));

        // Continua mesmo enquanto o questionário está sendo salvo
        continuarProximoPasso();
    }

    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono spacing={2}>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.Questionario.titulo}</SttHeading>
                        </SttHidden>
                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.Questionario.titulo}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
            <SttBoxAlofono>
                <SttGridContainerAlofono spacing={2}>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                    <SttGrid item xs={11} md={8}>
                        <SttHeading variant="h6">{strings.Questionario.descricao}</SttHeading>
                    </SttGrid>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

                {
                    perguntas.map((pergunta, index) => (
                        <SttGridContainerAlofono spacing={isXs ? 0 : 5} justifyContent="center" alignItems="center">
                            <SttHidden smDown>
                                <SttGrid item xs={2}></SttGrid>
                            </SttHidden>
                            <SttGrid item xs={10} md={8}>
                                <SttBoxPergunta 
                                    numero={index} 
                                    titulo={pergunta.titulo} 
                                    descricao={pergunta.descricao} 
                                    nomarginbottom={index === (perguntas.length - 1) ? 'true' : undefined} 
                                    ultima={index === (perguntas.length - 1)}
                                />
                            </SttGrid>
                            <SttHidden smDown>
                                <SttGrid item xs={2}></SttGrid>
                            </SttHidden>
                        </SttGridContainerAlofono>
                    ))
                }   

            <SttBoxAlofono>
                <SttGridContainerAlofono spacing={2}>
                    <SttHidden smDown>
                        <SttGrid item xs={4}></SttGrid>
                    </SttHidden>
                    <SttGrid item xs={12} md={4} className={classes.conteinerBotao}>
                        <SttButtonInternoAlofono 
                            variant="contained"
                            color="primary" 
                            onClick={responder}
                            disabled={!finalizado}
                        >
                            {strings.Questionario.resultado}
                        </SttButtonInternoAlofono>
                    </SttGrid>
                    <SttHidden smDown>
                        <SttGrid item xs={4}></SttGrid>
                    </SttHidden>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        triagemAuditiva: state.index.triagemAuditiva
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: () => dispatch(continuarProximoPasso()),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Triagem')(Questionario));