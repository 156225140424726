import TYPES from '../types';

export const setAuthenticated = (authenticated) => {
    return {
        type: TYPES.SET_AUTHENTICATED,
        payload: { authenticated }
    };
}

export const setTcleTime = (tcleTime) => {
    return {
        type: TYPES.SET_TCLE_TIME,
        payload: { tcleTime }
    };
}

export const setUser = (user) => {
    return {
        type: TYPES.SET_USER,
        payload: { user }
    };
}

export const setQuestionario = (respostas) => {
    return {
        type: TYPES.SET_RESPOSTA_QUESTIONARIO,
        payload: { respostas }
    };
}

export const setMessage = (message) => {
    return {
        type: TYPES.SET_MESSAGE,
        payload: { message }
    };
}

export const setRespostaQuestionario = (pergunta, resposta) => {
    return { 
        type: TYPES.RESPONDER_PERGUNTA,
        payload: { pergunta, resposta }
    };
}

export const resetarTeste = () => {
    return { 
        type: TYPES.RESETAR_TESTE
    };
}

export const setTabAtual = (tabAtual) => {
    return { 
        type: TYPES.SET_TAB_ATUAL,
        payload: tabAtual
    };
}

export const continuarProximoPasso = (passo) => {
    return { 
        type: TYPES.CONTINUAR_PROXIMO_PASSO,
        payload: { passo }
    };
}

export const setId = (id) => {
    return {
        type: TYPES.SET_ID,
        payload: { id }
    }
}

export const consideraProblemaAudicao = (resposta) => {
    return {
        type: TYPES.CONSIDERA_PROBLEMA_AUDICAO,
        payload: { resposta }
    }
}

export const compreendeConversaAmbientesRuidosos = (resposta) => {
    return {
        type: TYPES.COMPREENDE_CONVERSA_AMBIENTES_RUIDOSOS,
        payload: { resposta }
    }
}

export const setTipoFone = (tipo) => {
    return {
        type: TYPES.TIPO_FONE,
        payload: { tipo }
    }
}

export const setResultadoQuestionario = (resultado) => {
    return {
        type: TYPES.SET_RESULTADO_QUESTIONARIO,
        payload: { resultado }
    }
}

export const autorizaContato = (autoriza) => {
    return {
        type: TYPES.SET_AUTORIZA_CONTATO,
        payload: { autoriza }
    }
}

export const iniciarContagemTempoTela = (tela, timestamp) => {
    return {
        type: TYPES.INICIAR_CONTAGEM_TEMPO_TELA,
        payload: { tela, timestamp }
    }
}

export const finalizarContagemTempoTela = (tela, timestamp) => {
    return {
        type: TYPES.FINALIZAR_CONTAGEM_TEMPO_TELA,
        payload: { tela, timestamp }
    }
}