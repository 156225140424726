import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttTranslate,
    SttLoading,
    SttModal,
    SttButton
} from '@stt-componentes/core';
import themeSplashScreen from '../../../../themeSplashScreen.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { continuarProximoPasso, iniciarContagemTempoTela, finalizarContagemTempoTela } from '../../../../redux/actions';
import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import SttFeedbackAlofono from '../../../../componentes/geral/SttFeedbackAlofono';
import SttBoxIniciarQuestionario from '../../../../componentes/interno/triagemAuditiva/teste/componentes/SttBoxIniciarQuestionario';
import SttButtonInternoAlofono from '../../../../componentes/geral/SttButtonInternoAlofono';
import { setTesteResultado } from '../../../../redux/actions/teste';
import { getHeaders } from '../../../../request';

const useStyles = makeStyles(theme => ({
    botao: {}
}));

const REFERENCIA_RESULTADO = -11;

const Resultado = ({ user, strings, continuarProximoPasso, teste, setTesteResultado, triagemAuditiva, iniciarContagemTempoTela, finalizarContagemTempoTela }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [boaAudicao, setBoaAudicao] = useState(undefined);
    const [pularQuestionario, setPularQuestionario] = useState(true);
    const [loading, setLoading] = useState(true);
    const [salvar, setSalvar] = useState(true);
    const [modalAviso, setModalAviso] = useState(false);

    // useEffect(() => {
    //     if (user.respostaQuestionario) {
    //         setPularQuestionario(user.respostaQuestionario.length);
    //     }
    // }, [user]);

    useEffect(() => {
        let respostas = [...teste.respostas];

        // Tamanho: 48 [0 - 47]
        // Descarta primeira interação
        respostas.shift();

        // Tamanho: 47 [0 - 46]
        // Descarta a 24 interação (23 após shift())
        respostas.splice(23, 1);

        // Extrai os dados para cálculo do resultado
        const digito_1_sistema = respostas.map(resposta => resposta.primeiroDigitoSorteado.toString());
        const digito_1_paciente = respostas.map(resposta => resposta.primeiroDigito.toString());
        const digito_2_sistema = respostas.map(resposta => resposta.segundoDigitoSorteado.toString());
        const digito_2_paciente = respostas.map(resposta => resposta.segundoDigito.toString());
        const digito_3_sistema = respostas.map(resposta => resposta.terceiroDigitoSorteado.toString().toString());
        const digito_3_paciente = respostas.map(resposta => resposta.terceiroDigito.toString());

        const payloadResultado = { digito_1_sistema, digito_1_paciente, digito_2_sistema, digito_2_paciente, digito_3_sistema, digito_3_paciente };
        const ALOFONO_API_BASE_URL = global.gConfig.url_base_alofono;
        const ALOFONO_LAMBDA_API_BASE_URL = global.gConfig.url_base_alofono_lambda;

        const payloadRespostas = {
            idTdr: triagemAuditiva.id,
            respostas: respostas
        };

        if (salvar) {
            axios
                .post(`${ALOFONO_API_BASE_URL}/tdr`, payloadRespostas, { headers: getHeaders() })
                .then((response) => {
                    axios
                        .post(`${ALOFONO_LAMBDA_API_BASE_URL}/raw/tdr`, payloadResultado)
                        .then((response) => {
                            const { data } = response;
                            const { threshold, r_threshold } = data;
                            const resultado = threshold < REFERENCIA_RESULTADO && r_threshold < REFERENCIA_RESULTADO;
                            setTesteResultado(resultado);
                            setBoaAudicao(resultado);
                        })
                        .catch(err => console.error(err))
                        .finally(() => setLoading(false));
                })
                .catch(err => {
                    console.error(err);
                    setSalvar(false);
                    setModalAviso(true);
                });
        }

    }, [salvar]);

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono spacing={2}>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.resultadoTesteRuido}</SttHeading>
                        </SttHidden>
                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.resultadoTesteRuido}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <SttBoxAlofono $semMargemInferior={true}>
                <SttGridContainerAlofono justifyContent="center" alignItems="center">
                    <SttGrid item xs={10} md={6}>
                        <SttLoading open={loading} />
                        {
                            boaAudicao ?
                                <SttFeedbackAlofono $verde text={`${user.nome}, ${strings.feedbackVerdeTeste}`} />
                                :
                                !boaAudicao ?
                                    <SttFeedbackAlofono $laranja text={`${user.nome}, ${strings.feedbackLaranjaTeste}`} />
                                    :
                                    ''

                        }
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <SttBoxAlofono style={{paddingTop: isXs ? 0 : '48px'}}>
                <SttGridContainerAlofono style={{ textAlign: 'center' }}>
                    <SttGrid item xs={10} md={6}>
                        {
                            pularQuestionario ?
                                <SttButtonInternoAlofono
                                    className={classes.botao}
                                    variant="contained"
                                    color="primary"
                                    nomarginleft="true"
                                    onClick={() => continuarProximoPasso(9)}
                                >
                                    {strings.resultadoTriagem}
                                </SttButtonInternoAlofono>
                                :
                                <SttBoxIniciarQuestionario>
                                    <SttHidden smDown>
                                        <SttHeading variant="h6">{strings.continuarTriagemIniciarQustionario}</SttHeading>
                                    </SttHidden>
                                    <SttHidden smUp>
                                        <SttHeading variant="h4">{strings.continuarTriagemIniciarQustionario}</SttHeading>
                                    </SttHidden>
                                    <SttButtonInternoAlofono
                                        className={classes.botao}
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        onClick={() => continuarProximoPasso()}
                                    >
                                        {strings.iniciarQuestionario}
                                    </SttButtonInternoAlofono>
                                </SttBoxIniciarQuestionario>
                        }
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
            <ThemeProvider theme={themeSplashScreen}>
                <SttModal
                    title={strings.atencao}
                    open={modalAviso}
                    maxWidth="xs"
                    outModalClose={() => setModalAviso(false)}
                    iconClose={() => setModalAviso(false)}
                    fullWidth={true}
                    children={
                        <>
                            <SttGridContainerAlofono direction='column' style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                <SttGrid item xs={12}>
                                    <SttHeading variant="h6">{strings.avisoErroSalvar}</SttHeading>
                                </SttGrid>
                            </SttGridContainerAlofono>
                        </>
                    }
                    footer={
                        <div>
                            <SttButton variant="contained"
                                color="secondary"
                                onClick={() => {
                                    continuarProximoPasso(10);
                                }}>
                                {strings.nao}
                            </SttButton>
                            <SttButton variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setModalAviso(false);
                                    setSalvar(true);
                                }}>
                                {strings.sim}
                            </SttButton>
                        </div>
                    }
                />
            </ThemeProvider>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        teste: state.index.teste,
        triagemAuditiva: state.index.triagemAuditiva
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: (passo) => dispatch(continuarProximoPasso(passo)),
        setTesteResultado: (resultado) => dispatch(setTesteResultado(resultado)),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Triagem')(Resultado));