import React from 'react';
import { makeStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
    SttGrid,
    SttInput,
    SttTranslate
} from '@stt-componentes/core';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    input: {
        maxWidth: theme.spacing(14),
        '@media (min-width: 0px) and (max-width: 840px)': {
            maxWidth: theme.spacing(10)
        },
        margin: 0
    },
    inputEsquerda: {
        maxWidth: theme.spacing(14),
        '@media (min-width: 0px) and (max-width: 840px)': {
            maxWidth: theme.spacing(10)
        },
        "& .Mui-disabled": {
            borderRadius: '50px 0px 0px 50px',
        },
        margin: 0
    },
    inputDireita: {
        maxWidth: theme.spacing(14),
        '@media (min-width: 0px) and (max-width: 840px)': {
            maxWidth: theme.spacing(10)
        },
        "& .Mui-disabled": {
            borderRadius: '0px 50px 50px 0px'
        },
        margin: 0
    },
}));

const themeInputEsquerda = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                borderRadius: '50px 0px 0px 50px',
                height: '40px',
                '@media (min-width: 841px)': {
                    height: '68px'
                }
            },
            input: {
                color: 'black',
                align: 'center'
            }
        }
    }
});

const themeInputDireita = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                borderRadius: '0px 50px 50px 0px',
                height: '40px',
                '@media (min-width: 841px)': {
                    height: '68px'
                }
            },
            input: {
                color: 'black',
                paddingTop: '0px'
            }
        }
    }
});

const themeInputCentro = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                height: '40px',
                '@media (min-width: 841px)': {
                    height: '68px'
                }
            },
            input: {
                color: 'black'
            }
        }
    }
});

const BoxInputPilula = ({ passo, teste }) => {
    const classes = useStyles();

    return (
        <>
            <SttGrid container justifyContent="center" alignItems="center">
                <SttGrid item>
                    <MuiThemeProvider theme={themeInputEsquerda}>
                        <SttInput
                            disabled
                            value={teste.respostas[passo]?.primeiroDigito || ''}
                            className={classes.inputEsquerda}
                            inputProps={{
                                maxLength: 1,
                                style: {
                                    textAlign: 'center',
                                    fontSize: '2rem',
                                    padding: "0px"
                                }
                            }}
                        />
                    </MuiThemeProvider>
                </SttGrid>

                <SttGrid item>
                    <MuiThemeProvider theme={themeInputCentro}>
                        <SttInput
                            value={teste.respostas[passo]?.segundoDigito || ''}
                            disabled
                            className={classes.input}
                            inputProps={{
                                maxLength: 1,
                                style: {
                                    textAlign: 'center',
                                    fontSize: '2rem',
                                    padding: "0px"
                                }
                            }}
                        />
                    </MuiThemeProvider>
                </SttGrid>

                <SttGrid item>
                    <MuiThemeProvider theme={themeInputDireita}>
                        <SttInput
                            value={teste.respostas[passo]?.terceiroDigito || ''}
                            disabled
                            className={classes.inputDireita}
                            inputProps={{
                                maxLength: 1,
                                style: {
                                    textAlign: 'center',
                                    fontSize: '2rem',
                                    padding: "0px"
                                }
                            }}
                        />
                    </MuiThemeProvider>
                </SttGrid>
            </SttGrid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        teste: state.index.teste
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Teste')(BoxInputPilula));