const temPerfil = (usuario, perfil) => {
    return usuario.perfisRBAC.some(p => {
        return p.identificador === perfil;
    });
};

const temPermissaoRBAC = (usuario, permissao) => {
    return usuario?.permissoesRBAC?.includes(permissao);
};

export { temPerfil, temPermissaoRBAC };

