const stringsComuns = require('./comum');

const estomatoComum = {
    erroGerarTermo: 'Não foi possível gerar o termo de autorização. Tente imprimir o termo através da tela de exames.',
    exposicaoSol: 'Exposição dos lábios ao sol',
    exposicaoSolHorasDia: 'Exposição ao sol (h/dia)',
    exposicaoSolMesesAno: 'Exposição ao sol (meses/ano)',
    habitos: 'Hábitos (especialmente lesões vermelhas ulceradas ou lesões brancas)',
    lesao: 'Lesão',
    lesoes: 'Lesões',
    usoProtecaoSolar: 'Uso de proteção solar nos lábios'
};


module.exports = {
    abrangenciaProtese: 'Abrangência da prótese',
    alteracaoSensibilidade: 'Alteração da sensibilidade',
    alterarMeusDados: stringsComuns.alterarMeusDados,
    altura: 'Altura',
    anosFumou: 'Anos que fumou',
    anosUso: 'Anos de uso',
    antiga: 'Antiga',
    arcadaAfetada: 'Arcada afetada',
    assintomatica: 'Assintomática',
    assintomatico: 'Assintomático',
    aumentoSensibilidade: 'Aumento da sensibilidade',
    carregandoExame: 'Carregando exame',
    cirurgiaoDentista: 'Cirurgião dentista',
    consumoBebidasAlcoolicas: 'Consumo de bebidas alcoólicas',
    cor: 'Cor',
    dataAquisicao: 'Data de aquisição',
    dor: 'Dor',
    duasQuatroVezezMes: '2-4 vezes ao mês',
    duasTresVezesSemana: '2-3 vezez por semana',
    duracaoDor: 'Duração da dor',
    envioImagens: stringsComuns.envioImagens,
    espontaneidadeDor: 'Espontaneidade da dor',
    estruturaAfetada: 'Estrutura afetada',
    etilista: 'Consumo de bebida alcoólica',
    exames: stringsComuns.exames,
    exFumante: 'Ex-fumante',
    exposicaoSol: estomatoComum.exposicaoSol,
    exposicaoSolHorasDia: estomatoComum.exposicaoSolHorasDia,
    exposicaoSolMesesAno: estomatoComum.exposicaoSolMesesAno,
    exUsuario: 'Ex-usuário',
    frequenciaUsoProtecaoSolar: 'Frequência de uso de proteção solar nos lábios',
    fumante: 'Fumante',
    habitos: estomatoComum.habitos,
    inicio: stringsComuns.inicio,
    intensidadeDor: 'Intensidade da dor',
    ladoAfetado: 'Lado afetado',
    largura: 'Largura',
    laudo: stringsComuns.laudo,
    laudosGraves: estomatoComum.laudosGraves,
    lesaiSaiRaspagem: 'Lesão sai à raspagem?',
    lesao: 'Lesão',
    lesaoFundamental: 'Lesão fundamental',
    lesaoSaiRaspagem: 'Sai à raspagem',
    lesoes: 'Lesões',
    localizacaoDor: 'Localização da dor',
    localizacaoLesao: 'Localização',
    materiaisSuporte: stringsComuns.materiaisSuporte,
    mensalmente: 'Mensalmente',
    modulo: 'Estomatologia',
    mordedura: 'Mordedura',
    msgAlertaSemLesao: 'Por favor, não esquecer de preencher no campo da queixa principal o detalhamento do caso.',
    naoConhecido: 'Não conhecido',
    naoUsuario: 'Não usuário',
    nenhumaLesaoAdicionada: 'Nenhuma lesão adicionada',
    nenhummExameParaLaudo: 'Nenhum exame disponível para laudo',
    nomeSistema: stringsComuns.nomeSistema,
    notasAtualizacao: stringsComuns.notasAtualizacao,
    nova: 'Nova',
    observacaoSuperficie: 'Observação relacionada à superfície da lesão',
    observacoesAdicionaisLesao: 'Observações adicionais da região da lesão',
    ocorrencia: 'Ocorrência',
    outra: 'Outra',
    outraCor: 'Outra cor',
    outrasDrogas: 'Outras drogas',
    pacienteAssintomatico: 'Paciente assintomático',
    pacienteRealizouTratamentoPrevio: 'O paciente realizou algum tratamento prévio?',
    pacienteUsuarioProtese: 'Paciente usuário de prótese',
    palpacao: 'Palpação',
    parcial: 'Parcial',
    pendencias: stringsComuns.pendencias,
    perdaSensibilidade: 'Perda de sensibilidade',
    possibilidadeTraumaLocal: 'Possibilidade de trauma local',
    proteseMalAdaptada: 'Prótese mal adaptada',
    proximoExame: 'Próximo exame',
    quatroOuMaisSemana: '4 ou mais vezes por semana',
    queimacao: 'Queimação',
    queixaPrincipal: 'Queixa principal',
    regiaoAfetada: 'Região afetada',
    responsavelAquisicao: 'Responsável pela aquisição',
    sair: stringsComuns.sair,
    semLesao: 'Sem lesão',
    semTratamento: 'Sem tratamento',
    semTrauma: 'Sem trauma',
    sintomatologia: 'Sintomatologia',
    situacaoProtese: 'Situação da prótese',
    solicitacao: stringsComuns.solicitacao,
    superficie: 'Superfície',
    surtiuEfeito: 'Surtiu efeito?',
    tamanho: 'Tamanho',
    tempoEtilismo: 'Consome bebida alcoólica há quanto tempo?',
    tempoEvolucao: 'Tempo de evolução',
    tempoExEtilista: 'Parou de beber há quanto tempo?',
    tempoExTabagista: 'Parou de fumar há quanto tempo?',
    tempoExUsuarioDrogas: 'Parou de usar outras drogas há quanto tempo?',
    tempoTabagismo: 'Fuma há quanto tempo?',
    tempoUsoProtecaoSolar: 'Tempo de uso de proteção solar nos lábios',
    tempoUsuarioDrogas: 'Usa outras drogas há quanto tempo?',
    tipoBebida: 'Tipo de bebida',
    tipoCrescimento: 'Tipo de crescimento',
    tipoDor: 'Tipo de dor',
    tipoDroga: 'Tipo de droga',
    tipoFumo: 'Tipo de fumo',
    tipoTratamento: 'Tipo de tratamento',
    total: 'Total',
    tratamentoPrevio: 'Tratamentos prévios (se houver)',
    tratamentoPrevio: 'Tratamento prévio',
    unidadeMedida: 'Unidade de medida',
    usoProtecaoSolar: estomatoComum.usoProtecaoSolar,
    usuario: 'Usuário',
    usuarioDrogas: 'Uso de outras drogas'
};