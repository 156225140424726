import TYPES from '../types';

export const testeContinuarProximoPasso = () => {
    return {
        type: TYPES.TESTE_CONTINUAR_PROXIMO_PASSO
    };
}

export const testeIniciarPasso = () => {
    return {
        type: TYPES.TESTE_INICIAR_PASSO
    };
}

export const testeInformarDigito = (digito) => {
    return {
        type: TYPES.TESTE_INFORMAR_DIGITO,
        payload: { digito }
    };
}

export const testeApagarUltimoDigito = () => {
    return {
        type: TYPES.TESTE_APAGAR_ULTIMO_DIGITO
    };
}

export const setTesteResultado = (resultado) => {
    return {
        type: TYPES.TESTE_SET_RESULTADO,
        payload: { resultado }
    }
}