import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import moment from 'moment';
import { continuarProximoPasso, setResultadoQuestionario, iniciarContagemTempoTela, finalizarContagemTempoTela } from '../../../../redux/actions';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttTranslate
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import SttButtonInternoAlofono from '../../../../componentes/geral/SttButtonInternoAlofono';
import SttFeedbackAlofono from '../../../../componentes/geral/SttFeedbackAlofono';

const REFERENCIA_RESULTADO = 7.3;

const Resultado = ({ user, strings, continuarProximoPasso, questionario, setResultadoQuestionario, triagemAuditiva, iniciarContagemTempoTela, finalizarContagemTempoTela }) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [boaAudicao, setBoaAudicao] = useState(false);

    useEffect(() => {
        const total = questionario.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue));
        const resultado = (total / questionario.length) >= REFERENCIA_RESULTADO;
        setResultadoQuestionario(resultado);
        setBoaAudicao(resultado);
    }, []);

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono spacing={2}>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.Questionario.resultado}</SttHeading>
                        </SttHidden>
                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.Questionario.resultado}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                    <SttHidden smDown>
                        <SttGrid item xs={2}></SttGrid>
                    </SttHidden>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <SttBoxAlofono>
                <SttGridContainerAlofono justifyContent="center" alignItems="center">
                    <SttGrid item xs={10} md={6}>
                        {
                            boaAudicao ?
                                <SttFeedbackAlofono $verde text={`${user.nome}, ${strings.feedbackVerdeTeste}`} />
                                :
                                <SttFeedbackAlofono $laranja text={`${user.nome}, ${strings.feedbackLaranjaTeste}`} />
                        }
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <SttBoxAlofono style={{paddingTop: 0}}>
                <SttGridContainerAlofono style={{ textAlign: 'center' }}>
                    <SttGrid item xs={10} md={6}>
                        <SttButtonInternoAlofono variant="contained" color="primary" style={{marginLeft: 0}} onClick={() => continuarProximoPasso()}>
                            {strings.resultado}
                        </SttButtonInternoAlofono>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        questionario: state.index.triagemAuditiva.questionario,
        triagemAuditiva: state.index.triagemAuditiva
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: () => dispatch(continuarProximoPasso()),
        setResultadoQuestionario: (resultado) => dispatch(setResultadoQuestionario(resultado)),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Triagem')(Resultado));