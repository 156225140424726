module.exports = {
    resumoAnamnese: 'Resumo da anamnese e exame físico',
    examesComplementares: 'Exames complementares realizados',
    justificativaProcedimento: 'Justificativa do procedimento',
    funcionarioAdministrador: 'Funcionário Administrador',
    aguardandoAutorizador: 'Aguardando Autorizador',
    anoMesProcedencia: 'Ano e mês de Procedência',
    autorizacao: 'Autorização',
    autorizado: 'Autorizado',
    autorizadoAdministrador: 'Autorizado Administrador',
    cancelado: 'Cancelado',
    cid10Secundario: 'CID-10 Secundário',
    cidadeAutorizadora: 'Cidade Autorizadora',
    cidadeSolicitante: 'Cidade Solicitante',
    complementoProcedencia: 'Complemento da Procedência',
    dadosSigtap: 'Dados SIGTAP',
    dataAutorizacao: 'Data da Autorização',
    devolvido: 'Devolvido',
    erroPesquisaProcedimentoSigtap: 'Houve um erro na busca de procedimentos SIGTAP. Tente novamente mais tarde.',
    examesComplementares: 'Exames Complementares',
    funcionarioSolicitante: 'Funcionario Solicitante',
    instituicaoAutorizadora: 'Instituição Autorizadora',
    invalidado: 'Invalidado',
    justificativa: 'Justificativa',
    justificativaSolicitacao: 'Justificativa Solicitação',
    nomeAutorizador: 'Nome do Autorizador',
    pesquisaAPAC: 'Pesquisa de processos',
    procedenciaProcesso: 'Procedência do Processo',
    processoApac: 'Processo de APAC',
    processoCancelado: 'Processo Cancelado',
    processoDevolvido: 'Processo Devolvido',
    quantidade: 'Quantidade',
    rascunho: 'Rascunho',
    resumoExameFisico: 'Resumo do Exame Físico',
    solicitacaoNovoApac: 'Solicitação de novo processo',
    mensagemAberturaProcesso: 'Você deseja iniciar uma nova solicitação de APAC?',
    carregandoInstituicoesSolicitantes: 'Carregando instituicões solicitantes',
    carregandoInstituicoesAutorizadoras: 'Carregando instituições autorizadoras',
    erroIniciarNovaSolicitacao: 'Não foi possível inicializar a solicitação de APAC. Tente novamente mais tarde.',
    iniciandoSolicitacao: 'Iniciando solicitação',
    rascunhoSalvoSucesso: 'Rascunho salvo com sucesso',
    salvandoRascunho: 'Salvando rascunho',
    erroAlterarSolicitacao: 'Houve um erro ao editar a solicitação da APAC. Tente novamente mais tarde.',
    textoConfirmarRascunhoSolicitacao: 'Deseja salvar o rascunho da solicitação?',
    textoConfirmarEnvio: 'Deseja encaminhar a solicitação?',
    solicitacaoEnviada: 'Solicitação enviada com sucesso.',
    dadosProcedimento: 'Dados do procedimento'
};