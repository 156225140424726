import React from 'react';
import SttBoxGeralAlofono from '../../../../componentes/interno/triagemAuditiva/componentes/SttBoxGeralAlofono';
import styled from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import theme from '../../../../theme';
import { SttPaper } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 0,
        borderRadius: '8px'
    }
}));

const StyledSttBox = styled(SttBoxGeralAlofono)`
    text-align: center;
    border: ${props => `2px solid ${props.theme.palette.primary.main}`};
    border-radius: 8px;
`;

const SttBoxUnidadeSaudeAlofono = (props) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <SttPaper elevation={2} className={classes.paper}>
                <StyledSttBox {...props} />
            </SttPaper>
        </ThemeProvider>
    );
}
export default SttBoxUnidadeSaudeAlofono;