import React from 'react';
import { SttBox } from '@stt-componentes/core';
import styled from 'styled-components';
import { ThemeProvider } from "styled-components";
import theme from '../../../../theme';

const StyledSttBox = styled(SttBox)`
    padding: ${props => `${props.theme.spacing(4)}px`};
    @media (max-width: 840px) {
        padding: ${props =>
            `${props.theme.spacing(3)}px`
        };
    };    
`;

const SttBoxGeralAlofono = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <StyledSttBox
                {...props}
            />
        </ThemeProvider>
    );
}
export default SttBoxGeralAlofono;