import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Home from './paginas';
import { Provider } from 'react-redux';
import { Store } from './store';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

class App extends Component {
	render() {
		return (
			<>
				<NotificationContainer />
				<Provider store={Store}>
					<ThemeProvider theme={theme}>
                        <Home />
					</ThemeProvider>
				</Provider>
			</>
		)
	}

	getChildContext() {
		return {
			modulo: this.props.modulo,
			currentLanguage: this.props.currentLanguage
		};
	}
}

App.childContextTypes = {
	currentLanguage: PropTypes.string.isRequired,
    modulo: PropTypes.string.isRequired
};

export default App;