module.exports = {
    anexar: 'Anexar',
    anexarArquivo: 'Anexar arquivos',
    anexos: 'Lista de anexos',
    ano: 'Ano de nascimento',
    atendimento: 'Em atendimento',
    buscandoCpf: `Aguarde. Buscando dados do seu CPF...`,
    chamadaVideo: 'Iniciar atendimento por vídeo',
    chat: 'Teleatendimento',
    cnesInvalido: cnes => `A instituição com o CNES número ${cnes} não existe ou não está habilitada para utilizar o módulo de teleatendimento.`,
    concluido: 'Concluído',
    concordo: 'Concordo',
    dadosPessoais: 'Dados pessoais',
    dia: 'Dia de nascimento',
    digiteMensagem: 'Digite sua mensagem...',
    discordo: 'Discordo',
    enviandoArquivo: 'Enviando arquivo...',
    enviandoCadastro: 'Enviando cadastro...',
    enviarMensagem: 'Enviar mensagem',
    espera: 'Em espera',
    fecharChamadaVideo: 'Fechar atendimento por vídeo',
    finalizarAtendimento: 'Finalizar atendimento',
    historico: 'Histórico do dia',
    historicoChat: 'Histórico do chat',
    inserirAnexo: 'Inserir anexo',
    jaOnline: 'Você já está conectado no módulo de Teleatendimento através de outra aba, navegador ou dispotivo.',
    menuAtendimento: 'Atendimento',
    mes: 'Mês de nascimento',
    observacao: 'Digite uma observação',
    preCadastro: 'Para ir ao teleatendimento você deve fazer este pequeno pré-cadastro.',
    preenchaCpf: 'Preencha o CPF para começar o teleatendimento.',
    salvando: 'Salvando termo de consentimento...',
    selecionarEmoji: 'Selecionar emoji',
    selecioneArquivo: 'Selecione um arquivo',
    selecioneInstituicao: 'Instituição para atendimento',
    teleatendimento: 'Teleatendimento',
    teleatendimentoPaciente: 'Serviço de teleatendimento a pacientes',
    termo: 'Termo de consentimento',
    textoAguarde: 'Aguarde, em breve você será atendido.',
    textoAtendenteConectado: 'Atendente conectado, por favor retire sua dúvida.',
    textoAtendimentoFinalizado: 'O atendente encerrou este atendimento.',
    textoTermo: (cpf, nome) => `Termo de Consentimento Livre e Esclarecido para Teleconsulta.
                <br/>
                Por este instrumento particular, informo que me foram explicadas as características da Teleconsulta, as minhas responsabilidades e as do (a) médico(a) que me atenderá, e que eu concordo com o que me foi explicado e entendi minhas responsabilidades antes de iniciar a Teleconsulta. Recebi as seguintes informações antes da consulta:
                <br/>
                 <ul>
                    <li>A Teleconsulta é considerada como prática médica ética em caráter temporário e extraordinário, enquanto durar a emergência de saúde pública de importância internacional (ESPII) (artigo 3º da Lei nº 13.979/2020 e artigo 37º do Código de Ética Médica);</li>
                    <li>A plataforma digital utilizada na minha Teleconsulta segue as normas HIPAA compliance para assegurar a proteção e o sigilo das informações contra vazamento de dados (garante que a minha teleconsulta é segura e que os dados não serão vazados na internet);</li>
                    <li>Caso eu concorde, o (a) médico (a) poderá fazer uma gravação durante o atendimento, com o objetivo exclusivo de registro em prontuário de atendimento médico. Caso não seja feita a gravação, o (a) médico (a) fará o registro no seu prontuário médico;</li>
                    <li>Pelo sigilo do atendimento médico, não poderei fazer qualquer gravação sem autorização explícita do (a) médico (a) que prestou atendimento;</li>
                    <li>Durante a Teleconsulta, devo estar em local reservado (local separado, silencioso e que permita que eu possa me concentrar na consulta) sem a presença de outras pessoas, exceto as que o(a) médico(a) considerar necessárias para o processo do atendimento;</li>
                    <li>Todas as informações estão garantidas pelo sigilo médico, e como tal, devo garantir que permaneçam confidenciais ( tanto o médico quanto eu devemos garantir que o conteúdo da consulta permaneça em sigilo);</li>
                    <li>Todas as informações relatadas por mim são verdadeira, e eu compreendo que    devo relatar ao médico as informações claras, pois elas são fundamentais para a investigação médica;</li>
                    <li>Caso o (a) médico (a) considere necessário, poderá solicitar meu comparecimento presencial para a realização de exame físico ou exames complementares; </li>
                </ul> 
                <br/>
                Confirmo que li, compreendi e concordo com todos os termos deste Termo de Ciência e Consentimento Livre e Esclarecido para Teleconsulta,
                <br/>
                <b>Nome do paciente:</b> ${nome}
                <br/>
                <b>CPF do paciente:</b> ${cpf}
                <br/>`,
    usuariosParaAtendimento: 'Usuários para atendimento',
    validar: 'Validar',
    validarDados: 'Para validarmos seu cadastro, por favor digite os dados solicitados abaixo.'
}