import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import {
    SttGrid,
    SttCircularProgress,
    SttAlerta,
    SttTranslate
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import Rodape from '../../../componentes/interno/rodape';
import Functions from '../../../common/Functions';
import moment from 'moment';
import {
    iniciarContagemTempoTela, 
    finalizarContagemTempoTela
} from '../../../redux/actions';

const PaginaInicial = lazy(() => import('./paginaInicial'));
const SobreTriagemAuditiva = lazy(() => import('./sobreTriagemAuditiva'));

const TelaBoasVindas = ({ strings, iniciarContagemTempoTela, finalizarContagemTempoTela }) => {
    const location = useLocation();
    const secaoPaginaInicial = useRef(null);
    const secaoSobreTriagemAuditiva = useRef(null);

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [onClose] = useState(() => setMostrarAlerta(false));
    const [opcoesAlerta] = useState([{
        title: strings.ok,
        onClick: () => setMostrarAlerta(false)
    }]);

    useEffect(() => {
        if (location.pathname && location.pathname != '') {
            let node = null;
            switch (location.pathname) {
                case '/inicio':
                    node = secaoPaginaInicial.current;
                    break;

                case '/sobreTriagemAuditiva':
                    node = secaoSobreTriagemAuditiva.current;
                    break;

                default:
                    node = secaoPaginaInicial.current;
            }

            if (node) {
                node.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start'
                });
            }
        }
    }, [location]);

    useEffect(() => {
        const msgs = Functions.verificacaoBrowserSO(strings.mensagensBrowser);
        if (msgs.length > 0) {
            let arrMensagem = [];
            msgs.forEach(msg => {
                arrMensagem.push(`- ${msg}`);
            });
            setMensagemAlerta(arrMensagem.join('\n'));
            setMostrarAlerta(true);
        }

    }, []);

    useEffect(() => {
        iniciarContagemTempoTela(0, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(0, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    return (
        <SttGrid direction="column" container>
            <SttAlerta
                open={mostrarAlerta}
                title={strings.atencao}
                message={mensagemAlerta}
                type='alert'
                onClose={onClose}
                options={opcoesAlerta}
            />

            <SttGrid item xs={12}>
                <Suspense fallback={<SttCircularProgress color="primary" />}>
                    <div ref={secaoPaginaInicial}></div>
                    <PaginaInicial />
                </Suspense>
            </SttGrid>

            <SttGrid item xs={12}>
                <Suspense fallback={<SttCircularProgress color="primary" />}>
                    <div ref={secaoSobreTriagemAuditiva}></div>
                    <SobreTriagemAuditiva />
                </Suspense>
            </SttGrid>
            <Rodape />
        </SttGrid>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(null, mapDispatchToProps)(SttTranslate('TelaBoasVindas')(TelaBoasVindas));