module.exports = {
    abrirModulo: 'Abrir exame no módulo de',
    adicionarAnexo: 'Adicionar anexo',
    agendados: 'Agendados',
    anexoInserido: 'Anexo registrado',
    baixandoExame: 'Baixando exame',
    carregandoExame: 'Carregando o exame',
    categoria: 'Categoria',
    comLaudo: 'Com laudo',
    confirmaRemocaoAnexo: 'Você confirma a remoção do anexo?',
    contraRefercia: 'Contra-referência',
    dataHoraAtendimento: 'Data/hora do atendimento',
    descricaoExame: 'Descrição do exame',
    devolverListaGeral: 'Devolver para lista geral',
    direcionado: 'Direcionado',
    direcionados: 'Direcionado',
    direcionadosAMin: 'Direcionados a mim',
    encaminhamentoRevisao: 'Encaminhamento do laudo para revisão',
    erroDownloadWeasis: 'Download do Weasis indisponível. Tente novamente mais tarde.',
    exameDevolvidoListaGeral: 'Exame devolvido para lista geral',
    examesAgendados: 'Agendados',
    examesComImagens: 'Com imagens',
    exameSelecionado: 'Exame selecionado',
    exameSemImagens: 'Este exame ainda não possui imagens',
    exameSemPermissaoGaleria: 'A visualização de imagens deste exame não é permitida.',
    examesInvalidos: 'Inválidos',
    examesPacs: 'PACS',
    examesSelecionados: 'Exames selecionados',
    examesValidos: 'Válidos',
    exameValidado: 'Exame validado',
    formularioSolicitacaoMed: 'Formulário de solicitação de medicamentos',
    historicoPaciente: 'Histórico do paciente',
    indicacao: 'Indicação',
    indicacaoClinicaRegistrada: 'Indicação clínica registrada',
    invalido: 'Inválido',
    laudoAguardandoComplemento: 'Laudo aguardando complemento',
    laudoEmissao: 'Laudo em emissão',
    laudoRegistrado: 'Laudo registrado',
    laudosGraves: 'Laudos graves',
    laudoTemporario: 'Laudo temporário',
    medicoExecutor: 'Médico executor',
    modelosLaudoInstituicao: 'Modelo de laudo da instituição',
    modelosLaudoUsuario: 'Modelo de laudo do usuário',
    novaRede: 'Nova rede',
    carregandoHistoricoExames: 'Carregando histórico do exame',
    copiandoLaudo: 'Copiando laudo',
    numero_exame: 'Número do exame',
    numeroLaudo: 'Número do laudo',
    exameAtual: 'Exame atual',
    ohif: 'OHIF',
    opcoes: 'Opções',
    outrasInformacoes: 'Outras informações',
    oviyam: 'Oviyam',
    pacs: 'PACS',
    pdfIndisponivel: 'A geração do PDF deste exame apresentou erros e não está disponível. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    pesquisar: 'Pesquisar',
    placeholderSepareTermos: 'Separe os termos com espaço',
    priorizado: 'Priorizado',
    protocoloMed: 'Protocolo de medicamento',
    qualquerExecutor: 'Qualquer médico executor da instituição',
    qualquerTermo: 'Qualquer um dos termos',
    receitaMedica: 'Receita médica',
    redeAlterada: 'Rede alterada',
    responsavelEnvio: 'Responsável pelo envio',
    copiarLaudo: 'Copiar laudo',
    resumoLaudo: 'Resumo do laudo',
    rotina: 'Rotina',
    semImagensExibicao: 'Não há imagens disponíveis para exibição.',
    semLaudo: 'Sem laudo',
    semLaudo72h: 'Sem laudo há 72h',
    situacaoOrigem: 'Situação/Origem',
    somenteImagens: 'Somente exames com imagens',
    telediagnostico: 'Telediagnóstico',
    termosDescricao: 'Descrição do exame',
    tipoBusca: 'Tipo de busca por termo',
    todosTermos: 'Todos os termos',
    trocarRede: 'Trocar rede',
    ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
    valido: 'Válido',
    weasis: 'WEASIS'
};