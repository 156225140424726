import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttBox } from '@stt-componentes/core';
import { setRespostaQuestionario } from '../../../../../redux/actions'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import theme from '../../../../../theme';
import {
    SttGrid,
    SttHeading,
    SttSlider,
    SttTranslate
} from '@stt-componentes/core';
import SttGridContainerAlofono from '../../../../../componentes/geral/SttGridContainerAlofono';

const marks = [
    {
        value: '0',
        label: '0'
    },
    {
        value: '1',
        label: '1'
    },
    {
        value: '2',
        label: '2'
    },
    {
        value: '3',
        label: '3'
    },
    {
        value: '4',
        label: '4'
    },
    {
        value: '5',
        label: '5'
    },
    {
        value: '6',
        label: '6'
    },
    {
        value: '7',
        label: '7'
    },
    {
        value: '8',
        label: '8'
    },
    {
        value: '9',
        label: '9'
    },
    {
        value: '10',
        label: '10'
    }
];

const useStyles = makeStyles(theme => ({
    descritivo: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        '@media (min-width: 0px) and (max-width: 840px)': {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4)
        }
    },
    legenda: props => ({
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: props.ultima ? theme.spacing(6) : ''
    }),
    legendaDireita: props => ({
        transform: 'rotate(-90deg)',
        fontSize: '0.9rem',
        position: 'relative',
        whiteSpace: 'nowrap',
        right: props.ultima ? '-105px' : '-50px',
        marginBottom: props.ultima ? '123px' : '60px',
        '@media (min-width: 0px) and (max-width: 459px)': {
            fontSize: '0.8rem',
            marginBottom: props.ultima ? '120px' : '60px',
            right: props.ultima ? '-35px' : '-45px',
        },
        '@media (min-width: 460px) and (max-width: 840px)': {
            fontSize: '0.8rem',
            marginBottom: props.ultima ? '115px' : '60px',
            right: props.ultima ? '-94px' : '-45px',

        },
    }),
    legendaEsquerda: props => ({
        transform: 'rotate(-90deg)',
        fontSize: '0.9rem',
        position: 'relative',
        whiteSpace: 'nowrap',
        left: props.ultima ? '-73px' : '-50px',
        marginBottom: props.ultima ? '93px' : '70px',
        '@media (min-width: 0px) and (max-width: 840px)': {
            fontSize: '0.8rem',
            marginBottom: props.ultima ? '90px' : '60px',
            left: props.ultima ? '-65px' : '-45px',
        }
    }),
}));

const useStylesSlider = makeStyles(theme => ({
    rail: {
        '@media (min-width: 841px)': {
            height: '5px'
        }
    },
    mark: {
        '@media (min-width: 841px)': {
            height: '5px',
            width: '3px'
        }
    },
    markLabel: {
        top: '-20px',
        fontColor: theme.palette.text.primary
    },
}));

const StyledSttBox = styled(SttBox)`
    @media (min-width: 0px) and (max-width: 840px) {
        padding: ${props => `${props.theme.spacing(2)}px`};
    }
    padding: ${props => `${props.theme.spacing(3)}px`};
    background-color: #CBE9FF;
    margin-bottom: ${props => `${props.nomarginbottom ? '' : `${props.theme.spacing(3)}px`}`};
    border-radius: 8px
`;

const SttBoxPergunta = (props) => {
    const { strings, numero, titulo, descricao, questionario, setRespostaQuestionario, ultima = false, ...other } = props;
    const classes = useStyles(props);
    const classesSlider = useStylesSlider();

    const [valueLabelDisplay, setValueLabelDisplay] = useState('auto');

    return (
        <ThemeProvider theme={theme}>
            <StyledSttBox {...other}>
                <SttGridContainerAlofono spacing={2}>
                    <SttGrid item xs={12}>
                        <SttHeading variant='h3'>{titulo}</SttHeading>
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <div className={classes.descritivo}>
                            <SttHeading variant='h6'>{descricao}</SttHeading>
                        </div>
                    </SttGrid>
                </SttGridContainerAlofono>
                <SttGridContainerAlofono spacing={2}>
                    <SttGrid item xs={12}>
                        <div className={classes.legenda}>
                            <SttHeading variant='h6' className={classes.legendaEsquerda}>
                                {!ultima && strings.Questionario.deModoAlgum || strings.Questionario.precisaConcentrar}
                            </SttHeading>
                            <SttHeading variant='h6'className={classes.legendaDireita}>
                                {!ultima && strings.Questionario.perfeitamente || strings.Questionario.naoPrecisaConcentrar}
                            </SttHeading>
                        </div>
                        <SttSlider
                            step={1}
                            classes={classesSlider}
                            valueLabelDisplay={valueLabelDisplay}
                            valueLabelFormat={(value, index) => {
                                if (isNaN(value)) {
                                    setValueLabelDisplay('off');
                                } else {
                                    setValueLabelDisplay('on');
                                    return value;
                                }

                            }}
                            onChange={(event, value) => {
                                setRespostaQuestionario(numero, value);
                            }}
                            marks={marks}
                            track={false}
                            min={0}
                            max={10}
                        />
                    </SttGrid>
                </SttGridContainerAlofono>
            </StyledSttBox>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        questionario: state.index.triagemAuditiva.questionario
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRespostaQuestionario: (pergunta, resposta) => dispatch(setRespostaQuestionario(pergunta, resposta))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Triagem')(SttBoxPergunta));