import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttFormHelperText,
    SttTranslate
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../../componentes/geral/SttGridContainerAlofono';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SttBoxVerificacaoItens from './componentes/SttBoxVerificacaoItens';
import SttButtonInternoAlofono from '../../../../componentes/geral/SttButtonInternoAlofono';
import { 
    continuarProximoPasso, 
    setTipoFone,
    iniciarContagemTempoTela,
    finalizarContagemTempoTela 
} from '../../../../redux/actions';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    secaoBotaoContinuar: {
        padding: '0px !important',
        '@media (min-width: 0px) and (max-width: 840px)': {
            marginBottom: theme.spacing(6)
        }
    }
}));

const VerificacaoItens = ({ strings, continuarProximoPasso, triagemAuditiva, setTipoFone, iniciarContagemTempoTela, finalizarContagemTempoTela }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [submitCount, setSubmitCount] = useState(0);
    const [respostaFoneOuvido, setRespostaFoneOuvido] = useState(false);
    const [respostaLugarSilencioso, setRespostaLugarSilencioso] = useState(false);

    const tratarResposta = () => {
        setSubmitCount(submitCount + 1);
        if(!respostaFoneOuvido || !respostaLugarSilencioso || !triagemAuditiva.tipoFone) {
            return;
        }
        continuarProximoPasso();
    }

    useEffect(() => {
        iniciarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));

        return () => finalizarContagemTempoTela(triagemAuditiva.passo, moment().parseZone().format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    return (
        <>
            <SttBoxAlofono $azul>
                <SttGridContainerAlofono spacing={isXs ? 2 : 5}>
                    <SttGrid item xs={12} md={8}>
                        <SttHidden smDown>
                            <SttHeading variant="h2">{strings.preparandoTeste}</SttHeading>
                        </SttHidden>

                        <SttHidden mdUp>
                            <SttHeading variant="h3">{strings.preparandoTeste}</SttHeading>
                        </SttHidden>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            <SttBoxAlofono>
                <SttGridContainerAlofono spacing={isXs ? 0 : 5} justifyContent="center" alignItems="center">
                    <SttGrid item xs={12} md={6}>
                        <SttBoxVerificacaoItens 
                            titulo={strings.titulo} 
                            strings={strings} 
                            respostaFoneOuvido={respostaFoneOuvido}
                            respostaLugarSilencioso={respostaLugarSilencioso}
                            setRespostaFoneOuvido={setRespostaFoneOuvido}
                            setRespostaLugarSilencioso={setRespostaLugarSilencioso}
                            tipoFone={triagemAuditiva.tipoFone}
                            setTipoFone={setTipoFone}
                        />
                        {
                            submitCount > 0 && !(respostaFoneOuvido && respostaLugarSilencioso && triagemAuditiva.tipoFone) &&
                            <SttFormHelperText error>
                                {strings.marcarItensObrigatorio}
                            </SttFormHelperText>
                        }
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>

            {/* Container para visualização do botão de continuar */}
            <SttBoxAlofono className={classes.secaoBotaoContinuar}>
                <SttGridContainerAlofono spacing={isXs ? 0 : 3} direction='column' style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                    <SttGrid item xs={12}>
                        <SttButtonInternoAlofono variant="contained" color="primary" nomarginleft="true" onClick={() => tratarResposta()}>
                            {strings.continuar}
                        </SttButtonInternoAlofono>
                    </SttGrid>
                </SttGridContainerAlofono>
            </SttBoxAlofono>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        tcleTime: state.index.tcleTime,
        triagemAuditiva: state.index.triagemAuditiva
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        continuarProximoPasso: () => dispatch(continuarProximoPasso()),
        setTipoFone: (tipo) => dispatch(setTipoFone(tipo)),
        iniciarContagemTempoTela: (tela, timestamp) => dispatch(iniciarContagemTempoTela(tela, timestamp)),
        finalizarContagemTempoTela: (tela, timestamp) => dispatch(finalizarContagemTempoTela(tela, timestamp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('PreparandoTeste')(VerificacaoItens));