import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

const initializeApp = (config) => {
    global.gConfig = config;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    root.render(<App modulo={'alofono'} currentLanguage={'ptbr'} />);
};

fetch(`${process.env.PUBLIC_URL}/config/config.json`, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((config) => initializeApp(config));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();