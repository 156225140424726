import React, { useState } from 'react';
import {
    SttGrid,
    SttHeading,
    SttPaper,
    SttDivider,
    SttCheckbox
} from '@stt-componentes/core';
import SttGridContainerAlofono from '../../../../../componentes/geral/SttGridContainerAlofono';
import { makeStyles } from "@material-ui/core/styles";
import PlugP2Logo from '../../../../../img/plugP2Bluetooth.svg';
import VolumeOffLogo from '../../../../../img/volumeOff.svg';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    containerGeral: {
        margin: 0
    }
}));

const SttBoxVerificacaoItens = ({ titulo, strings, submitCount, ...props }) => {
    const classes = useStyles();
    const {
        respostaFoneOuvido,
        respostaLugarSilencioso,
        setRespostaFoneOuvido,
        setRespostaLugarSilencioso,
        tipoFone
    } = props;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <>
            <SttPaper elevation={2}>
                <SttGridContainerAlofono spacing={isXs ? 3 : 1} className={classes.containerGeral}>
                    <SttGrid item container spacing={3}>
                        <SttGrid item xs={12}>
                            <SttHeading variant="h5">{titulo}</SttHeading>
                        </SttGrid>

                        <SttGrid item xs={12}>
                            <SttGrid container alignItems="center">
                                <SttGrid item container xs={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={PlugP2Logo} />
                                </SttGrid>
                                <SttGrid item container xs={8} style={{ alignItems: 'center', width: '100%' }}>
                                    <SttHeading variant="h5">{strings.foneConectado}</SttHeading>
                                </SttGrid>
                                <SttGrid item container xs={2} style={{ justifyContent: 'center' }}>
                                    <SttCheckbox
                                        type="radio"
                                        value={true}
                                        color="primary"
                                        checked={respostaFoneOuvido}
                                        onClick={() => {
                                            setRespostaFoneOuvido(!respostaFoneOuvido);
                                        }}
                                    />
                                </SttGrid>
                            </SttGrid>

                        </SttGrid>

                        <SttGrid item xs={12} style={{ width: '100%' }}>
                            <SttDivider />
                        </SttGrid>

                        <SttGrid item xs={12}>
                            <SttGrid container alignItems="center">
                                <SttGrid item container xs={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={VolumeOffLogo} />
                                </SttGrid>
                                <SttGrid item container xs={8} style={{ alignItems: 'center', width: '100%' }}>
                                    <SttHeading variant="h5">{strings.localSilencioso}</SttHeading>
                                </SttGrid>
                                <SttGrid item container xs={2} style={{ justifyContent: 'center' }}>
                                    <SttCheckbox
                                        type="radio"
                                        value={true}
                                        color="primary"
                                        checked={respostaLugarSilencioso}
                                        onClick={() => {
                                            setRespostaLugarSilencioso(!respostaLugarSilencioso);
                                        }}
                                    />
                                </SttGrid>
                            </SttGrid>
                        </SttGrid>
                    </SttGrid>
                </SttGridContainerAlofono>

            </SttPaper>
        </>

    );
}
export default SttBoxVerificacaoItens;