import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttGrid,
    SttHeading,
    SttHidden,
    SttTranslate
} from '@stt-componentes/core';
import SttBoxAlofono from '../../../componentes/geral/SttBoxAlofono';
import SttGridContainerAlofono from '../../../componentes/geral/SttGridContainerAlofono';
import PranchetaDois from '../../../img/prancheta2.png';
import { useTheme } from '@material-ui/core/styles';
import Rodape from '../rodape';
import UFSCLogo from '../../../img/ufsc.svg';
import LVALogo from '../../../img/lva.svg';
import FAPESCLogo from '../../../img/fapesc.svg';
import SUSLogo from '../../../img/sus.svg';
import GEPALogo from '../../../img/gepa.png';

const useStyles = makeStyles(theme => ({
    texto: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2)
    },
    boneca: {
        maxWidth: theme.spacing(43),
        position: 'relative',
        bottom: '-50px',
        '@media (max-width: 840px)': {
            maxWidth: theme.spacing(32),
            bottom: '-30px'
        }
    },
    fundoCinza: {
        backgroundColor: '#F5F5F5'
    },
}));

const Resultado = ({ strings }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            <SttHidden xsDown>
                <SttBoxAlofono $azul style={{ marginTop: theme.spacing(15), marginBottom: theme.spacing(15) }}>
                    <SttGridContainerAlofono spacing={2}>
                        <SttGrid item md={2}></SttGrid>
                        <SttGrid item md={8}>
                            <SttGridContainerAlofono spacing={2} style={{alignItems: 'end'}}>
                                <SttGrid item md={7} className={classes.texto}>
                                    <SttHeading variant="h2">{strings.agradecimento}</SttHeading>
                                </SttGrid>
                                <SttGrid item md={4} className={classes.texto}>
                                    <img src={PranchetaDois} className={classes.boneca} />
                                </SttGrid>
                            </SttGridContainerAlofono>
                        </SttGrid>
                        <SttGrid item md={2}></SttGrid>
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
            </SttHidden>
            <SttHidden smUp>
                <SttBoxAlofono $azul style={{ marginTop: theme.spacing(8), marginBottom: theme.spacing(8), textAlign: 'center' }}>
                    <SttGridContainerAlofono spacing={2}>
                        <SttGrid item md={1}></SttGrid>
                        <SttGrid item md={10}>
                            <SttGridContainerAlofono spacing={2}>
                                <SttGrid item md={6} className={classes.texto}>
                                    <SttHeading variant="h2">{strings.agradecimento}</SttHeading>
                                </SttGrid>
                                <img src={PranchetaDois} className={classes.boneca} />
                            </SttGridContainerAlofono>
                        </SttGrid>
                        <SttGrid item md={1}></SttGrid>
                    </SttGridContainerAlofono>
                </SttBoxAlofono>
            </SttHidden>

            {/* Container para visualização dos logos de realização */}
            <SttBoxAlofono className={classes.fundoCinza}>
                <SttHidden smDown>
                    <SttGridContainerAlofono alignItems="center" spacing={5}>
                        <SttGrid item xs={1}></SttGrid>
                        <SttGrid item xs={2} container spacing={0}>
                            <SttHeading variant="h5">{strings.realizacao}</SttHeading>
                        </SttGrid>
                        <SttGrid item xs={2}></SttGrid>
                        <SttGrid item xs={1}>
                            <img src={UFSCLogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={1}>
                            <img src={LVALogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={1}>
                            <img src={GEPALogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={1}>
                            <img src={FAPESCLogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={1}>
                            <img src={SUSLogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={1}></SttGrid>
                    </SttGridContainerAlofono>
                </SttHidden>

                <SttHidden mdUp>
                    <SttGridContainerAlofono alignItems="center" spacing={2}>
                        <SttGrid item xs={12}>
                            <SttHeading variant="h3">{strings.realizacao}</SttHeading>
                        </SttGrid>
                        <SttGrid item xs={2} md={1}>
                            <img src={UFSCLogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={2} md={1}>
                            <img src={LVALogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={2} md={1}>
                            <img src={GEPALogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={2} md={1}>
                            <img src={FAPESCLogo} style={{ width: '100%' }} />
                        </SttGrid>
                        <SttGrid item xs={2} md={1}>
                            <img src={SUSLogo} style={{ width: '100%' }} />
                        </SttGrid>
                    </SttGridContainerAlofono>
                </SttHidden>
            </SttBoxAlofono>

            <Rodape />
        </>
    );
};

export default SttTranslate('Triagem')(Resultado);