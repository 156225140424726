import React from 'react';
import { SttBox, SttHeading } from '@stt-componentes/core';
import styled from 'styled-components';
import { ThemeProvider } from "styled-components";
import theme from '../../theme';
import ThumbsUp from '../../img/thumbsUp.svg';
import PriorityHigh from '../../img/priorityHigh.svg';

const StyledSttBox = styled(SttBox)`
    text-align: center;
    border-radius: 4px;
    padding: ${props =>
        `${props.theme.spacing(4)}px`
    };
    background-color: ${props =>
        props.$verde ? '#BEDDC1' : props.$laranja ? '#FBE1D4' : ''
    };
    @media (max-width: 840px) {
        padding: ${props => `${props.theme.spacing(2)}px`
    };
    };
`;

const SttFeedbackAlofono = (props) => {
    const { $verde, $laranja, text } = props;
    return (
        <ThemeProvider theme={theme}>
            <StyledSttBox {...props}>
                {
                    $laranja
                        ?
                        <>
                            <img src={PriorityHigh} />
                            <SttHeading variant="h4">{text}</SttHeading>
                        </>
                        :
                        $verde &&
                        <>
                            <img src={ThumbsUp} />
                            <SttHeading variant="h4" dangerouslySetInnerHTML={{ __html: text }} />
                        </>
                }
            </StyledSttBox>
        </ThemeProvider>
    );
}

export default SttFeedbackAlofono;